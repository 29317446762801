import { Button, Flex } from "@aws-amplify/ui-react";
import Modal from "./components/Modal";
import { PropsWithChildren } from "react";

export default ({
  title,
  children,
  onClose,
}: PropsWithChildren<{
  title: string;
  onClose: () => void;
}>) => {
  return (
    <>
      <Modal size="sm" title={title} onClose={onClose}>
        <Flex direction={"column"} gap={16}>
          <Flex direction={"column"} gap={0}>
            {children}
          </Flex>
        </Flex>

        <Flex marginTop={32}>
          <Button onClick={() => onClose()} grow={1}>
            OK
          </Button>
        </Flex>
      </Modal>
    </>
  );
};
