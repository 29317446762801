import { useState } from "react";
import { UserDocument } from "../../Docs/UserDocument";
import { API } from "../utils/API";
import { useApi } from "./useApi";
import { useFindOneLazyApi, useLazyApi, useUpdateApi } from "./useLazyApi";
import { UserDetails } from "../types/user-details";
import { CoreUserData } from "../types/core-user-data";

export interface UserDetailsWithDocs extends UserDetails {
  docs: UserDocument[];
}

export type UserUpdateDto = Pick<
  UserDetails,
  | "email"
  | "first_name"
  | "last_name"
  | "address_line"
  | "zip_code"
  | "city"
  | "country"
  | "vat_id"
  | "company_name"
  | "language"
>;

export enum UserSort {
  Id = "id",
  FirstName = "firstName",
  LastName = "lastName",
}

export interface CognitoUser {
  cognito_id: string;
  email: string;
}

export enum CognitoGroup {
  Service = "Service",
  Admin = "Admin",
  Accounting = "Accounting",
  Coordinator = "Coordinator",
}

interface UserQuery {
  query?: string;
  group?: CognitoGroup[];
}

export const useUsers = () =>
  useApi<UserDetails[], UserQuery>(`/v1/admin/contracts/users`);

export const useContractsLazyUsers = () =>
  useLazyApi<UserDetails[], UserQuery>(`/v1/admin/contracts/users`);

export const useLazyUsers = () =>
  useLazyApi<UserDetails[], UserQuery>(`/v1/admin/users`);

export const useUserData = () => {
  return useFindOneLazyApi<CoreUserData>(`/v1/admin/users`);
};

export const useUpdateUserData = (userId: number) => {
  return useUpdateApi<Partial<CoreUserData>>(`/v1/admin/users/${userId}`);
};

export const useUser = (userId: number) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [savingSuccess, setSavingSuccess] = useState<boolean>(false);
  const [savingError, setSavingError] = useState<Error>();

  const {
    data: userDetails,
    error: loadingError,
    loading: isLoading,
    refetch,
  } = useApi<UserDetailsWithDocs[], undefined>(
    `/v1/admin/contracts/users/${userId}`
  );

  const updateUser = async (userId: number, data: UserUpdateDto) => {
    try {
      setIsSaving(true);
      setSavingSuccess(false);
      setSavingError(undefined);

      await API.put(`/v1/admin/contracts/users/${userId}`, {
        body: JSON.stringify(data),
      });

      setSavingSuccess(true);
    } catch (error) {
      setSavingError(error as Error);
    } finally {
      setIsSaving(false);
    }
  };

  return {
    refetch,
    userDetails,
    updateUser,
    isLoading,
    loadingError,
    isSaving,
    savingError,
    savingSuccess,
  };
};
