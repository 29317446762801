import { Divider, Flex } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
import { MdContentPaste, MdHouse, MdPersonAdd } from "react-icons/md";
import SideMenuLink from "../shared/components/SideMenuLink";
import TextMuted from "../shared/components/TextMuted";

export default () => {
  const { t } = useTranslation();

  return (
    <>
      <Flex direction="column">
        <TextMuted>{t("Service.Coordination")}</TextMuted>
        <Divider></Divider>
      </Flex>

      <SideMenuLink
        label={t("Service.CleaningSchedule")}
        icon={<MdContentPaste />}
        href="/service/planning"
      ></SideMenuLink>

      <SideMenuLink
        label={t("Service.Properties")}
        icon={<MdHouse />}
        href="/service/properties"
      ></SideMenuLink>

      <SideMenuLink
        label={t("Service.ManagePeople")}
        icon={<MdPersonAdd />}
        href="/service/people"
      ></SideMenuLink>
    </>
  );
};
