import { Button, Flex } from "@aws-amplify/ui-react";
import Modal from "../shared/components/Modal";

export default ({
  title,
  onClose,
  onConfirmed,
}: {
  title: string;
  onConfirmed: () => void;
  onClose: () => void;
}) => {
  return (
    <>
      <Modal size="sm" title={title} onClose={onClose}>
        <Flex direction={"column"} gap={16}>
          <Flex direction={"column"} gap={0}>
            Are you sure?
          </Flex>
        </Flex>

        <Flex marginTop={32}>
          <Button
            onClick={async () => {
              onClose();
            }}
            variation="primary"
            grow={1}
          >
            Cancel
          </Button>

          <Button onClick={() => onConfirmed()} grow={1}>
            Confirm
          </Button>
        </Flex>
      </Modal>
    </>
  );
};
