import { IStatementProposal } from "../../shared/hooks/useOwnerStatement";
import { RTableColumn } from "../../shared/components/tables/TableColumn";

export const columns: RTableColumn<IStatementProposal>[] = [
  {
    label: "Payout Title",
    fieldKey: "statement_title",
  },
  {
    fieldKey: "account_mail",
    label: "Account",
  },
  {
    fieldKey: "statement_title",
    label: "Settlement",
  },
  {
    type: "Number",
    fieldKey: "payout_base",
    textAlign: "right",
    label: "Payout Base",
  },
  {
    type: "Number",
    fieldKey: "total_expenses",
    textAlign: "right",
    label: "Expenses / Extras",
  },
  {
    type: "Number",
    fieldKey: "utility_cost",
    textAlign: "right",
    label: "Utility Cost",
  },
  {
    type: "Number",
    fieldKey: "prorated_payout",
    textAlign: "right",
    label: "Prorated Payout",
  },
  {
    label: "PDF",
  },
  {
    fieldKey: "vat_id",
    label: "VAT Payer",
  },
  {
    label: "Warnings",
  },
  {
    label: "Approved",
  },
  {
    label: "Published",
  },
  {
    label: "Action",
  },
];
