import { Modal } from "react-bootstrap";
import React from "react";

interface ModalProps {
  children: React.ReactElement | React.ReactElement[];
  title: string;
  onClose: () => void;
  size?: "xl" | "sm";
  hideCloseBtn?: boolean;
}

export default ({
  children,
  title,
  onClose,
  size,
  hideCloseBtn,
}: ModalProps) => {
  return (
    <Modal
      centered={size === "sm" ? true : false}
      size={size}
      show={true}
      scrollable={true}
      onHide={onClose}
      onBackdropClick={onClose}
      onExit={onClose}
    >
      <Modal.Header closeButton={!hideCloseBtn}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};
