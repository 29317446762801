import { debounce } from "lodash";
import moment from "moment";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { useTaskUpdate } from "../shared/hooks/useTasksApi";
import TaskNotesComponent from "./TaskNotesComponent";

export default ({
  taskId,
  defaultValue,
  isReadOnly,
}: {
  taskId: string;
  isReadOnly?: boolean;
  defaultValue?: string;
}) => {
  // use "en" locale in admin pages
  moment.locale("en");
  const params = useParams();

  const { update, loading: updating } = useTaskUpdate(taskId || params.taskId!);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce((value: string) => {
      update({
        task_id: taskId,
        notes: value,
      });
    }, 1000),
    [update]
  );

  return (
    <TaskNotesComponent
      isReadOnly={isReadOnly}
      onChange={handleSearch}
      updating={updating}
      defaultValue={defaultValue}
    ></TaskNotesComponent>
  );
};
