import { Flex, Heading } from "@aws-amplify/ui-react";
import { Accordion } from "react-bootstrap";
import { MdDoorFront, MdOutlineDirections } from "react-icons/md";
import MarkdownContainer from "../shared/components/MarkdownContainer";
import TextMuted from "../shared/components/TextMuted";
import { TaskGetResponse } from "../shared/hooks/useTasksApi";

export default function TaskPropertyAccess({
  data,
}: {
  data: Pick<TaskGetResponse, "accessInfo">;
}) {
  return (
    <Flex direction={"column"}>
      {!data.accessInfo && (
        <Flex alignItems={"center"}>
          <TextMuted>
            You can't access now the access codes. Access codes are made
            available on the day of the day.
          </TextMuted>
        </Flex>
      )}

      {data.accessInfo && (
        <Flex direction={"column"} minHeight={300}>
          <Flex direction={"column"}>
            <Accordion defaultActiveKey="0" alwaysOpen={true}>
              <Accordion.Item eventKey={"1"}>
                <Accordion.Header>
                  <Flex alignItems={"center"}>
                    <MdOutlineDirections></MdOutlineDirections>
                    <Heading>How to get there?</Heading>
                  </Flex>
                </Accordion.Header>
                <Accordion.Body>
                  <MarkdownContainer
                    text={data.accessInfo.checkin_instructions}
                  />
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey={"2"}>
                <Accordion.Header>
                  <Flex alignItems={"center"}>
                    <MdDoorFront></MdDoorFront>
                    <Heading>Door code</Heading>
                  </Flex>
                </Accordion.Header>
                <Accordion.Body>
                  <MarkdownContainer text={data.accessInfo.door_code} />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}
