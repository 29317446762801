import { Flex, Heading } from "@aws-amplify/ui-react";
import UserDataUpdateForm from "../../shared/components/UserDataUpdateForm";
import { noop } from "lodash";
import { useUserProfile } from "../../shared/components/UserProfileProvider";
import { updateCurrentUserData } from "../../shared/hooks/useCurrentUser";

export default () => {
  const { userProfile } = useUserProfile();

  return (
    <Flex direction="column">
      <Heading>Profile</Heading>

      {userProfile && (
        <UserDataUpdateForm
          handleUpdate={async (values) => {
            await updateCurrentUserData({
              phone: values.phone,
              first_name: values.first_name,
              last_name: values.last_name,
              service_region: values.service_region,
            });
          }}
          userId={userProfile.id}
          onSaved={noop}
        ></UserDataUpdateForm>
      )}
    </Flex>
  );
};
