import Autocomplete from "./Autocomplete";
import { CoordinationTaskType } from "../shared/hooks/core-coordination-task";
import { useTranslation } from "react-i18next";

export default ({
  onSelected,
  label,
  defaultValue,
}: {
  onSelected: (type: CoordinationTaskType) => Promise<void>;
  defaultValue?: string;
  label?: string;
}) => {
  const { t } = useTranslation();

  return (
    <Autocomplete
      defaultLabel={defaultValue}
      onSelected={async (option) => {
        await onSelected(option.id as CoordinationTaskType);
      }}
      isLoading={false}
      onOptionsRequested={() => void 0}
      options={
        [
          {
            id: CoordinationTaskType.Cleaning,
            label: t(`Service.Cleaning`),
          },
          {
            id: CoordinationTaskType.Maintenance,
            label: t(`Service.Maintenance`),
          },
          {
            id: CoordinationTaskType.Delivery,
            label: t(`Service.Delivery`),
          },
          {
            id: CoordinationTaskType.Inspection,
            label: t(`Service.Inspection`),
          },
        ] as { id: CoordinationTaskType; label: string }[]
      }
      label={label || `${t("Service.TaskType")} *`}
    ></Autocomplete>
  );
};
