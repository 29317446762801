import { useState } from "react";
import AddButton from "../shared/components/AddButton";
import AddTaskModal from "./AddTaskModal";
import { Flex } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";

export default ({ onCreated }: { onCreated: (taskId: string) => void }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Flex direction={"column"}>
        <AddButton
          label={t("Service.AddTask")}
          onClick={() => {
            setOpen(true);
          }}
        ></AddButton>
      </Flex>
      {open && (
        <AddTaskModal
          onCreated={(taskId) => {
            setOpen(false);
            onCreated(taskId);
          }}
          onClose={() => {
            setOpen(false);
          }}
        />
      )}
    </>
  );
};
