import {
  Autocomplete,
  ComboBoxOption,
  Flex,
  Heading,
  TextField,
  AutocompleteProps,
} from "@aws-amplify/ui-react";
import { useCallback, useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import { logger } from "../../logger";
import { debounce } from "lodash";
import Loader from "../shared/components/Loader";

export default ({
  onSelected,
  defaultLabel,
  onOptionsRequested,
  isReadOnly,
  ...autocompleteProps
}: Pick<AutocompleteProps, "options" | "label" | "isLoading" | "isReadOnly"> & {
  onSelected: (option: ComboBoxOption) => Promise<void>;
  defaultLabel?: string | undefined;
  onOptionsRequested: (query?: string) => void;
}) => {
  const [value, setValue] = useState<ComboBoxOption | undefined>(
    defaultLabel ? { id: "", label: defaultLabel } : undefined
  );
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [savingError, setSavingError] = useState<string | undefined>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce(
      (queryString: string) => {
        if (queryString.length > 1) {
          onOptionsRequested(queryString);
        }
      },
      1000,
      { leading: true, trailing: true }
    ),
    [onOptionsRequested]
  );

  useEffect(() => {
    if (isEditMode) {
      onOptionsRequested();
    }
  }, [isEditMode, onOptionsRequested]);

  return (
    <Flex grow={1} direction={"column"} gap={0}>
      <Heading>{autocompleteProps.label}</Heading>

      {!isEditMode && (
        <Flex direction={"column"}>
          <Flex alignItems={"center"}>
            <TextField
              disabled={isReadOnly}
              readOnly
              isReadOnly={true}
              onClick={() => {
                if (!saving && !isReadOnly) {
                  setIsEditMode(true);
                }
              }}
              label=""
              value={value?.label || ""}
              grow={1}
            />

            {saving && <Loader></Loader>}
            {!saving && !isReadOnly && (
              <MdEdit
                onClick={() => {
                  setIsEditMode(true);
                }}
              ></MdEdit>
            )}
          </Flex>
        </Flex>
      )}

      {isEditMode && (
        <Flex direction={"column"} marginTop={8}>
          <Flex>
            <Autocomplete
              padding={0}
              onBlur={() => {
                setIsEditMode(false);
              }}
              margin={0}
              autoFocus={true}
              renderOption={(option) => option.label}
              menuSlots={
                autocompleteProps.isLoading
                  ? { Empty: <Loader></Loader> }
                  : undefined
              }
              hasSearchIcon={true}
              onChange={(event) => handleSearch(event.target.value)}
              width={"100%"}
              inputStyles={{ flex: 1 }}
              style={{ flexGrow: 1 }}
              grow={1}
              flex={1}
              optionFilter={(option, value) => {
                return option.label
                  .toLocaleLowerCase()
                  .includes(value.toLowerCase());
              }}
              label="Hostaway ID"
              errorMessage={savingError}
              hasError={!!savingError}
              isLoading={
                autocompleteProps.isLoading && !autocompleteProps.options
              }
              options={autocompleteProps.options}
              onSelect={async (option) => {
                setValue(option);

                setSaving(true);
                setSavingError(undefined);

                try {
                  await onSelected(option);

                  setIsEditMode(false);
                } catch (err) {
                  logger.error(err);

                  setSavingError(
                    `Error while saving: ${JSON.stringify(
                      (err as any)?.message
                    )}`
                  );
                } finally {
                  setSaving(false);
                }
              }}
              onClear={() => {}}
            />
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
