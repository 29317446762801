import { useCallback, useState } from "react";
import { logger } from "../../../logger";
import { API } from "../utils/API";
import { buildQueryString } from "./buildQueryString";

export const useCreateApi = <T>(relativeUrl: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>();
  const [data, setData] = useState<T | undefined>(undefined);

  const create = useCallback(
    async (body?: T) => {
      try {
        setLoading(true);

        const data: T = await API.post(`${relativeUrl}`, { body });

        setData(data);

        return data;
      } catch (error) {
        logger.error(error);

        setError(error as Error);
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setError, relativeUrl]
  );

  return { loading, error, create, data };
};

export const useUpdateApi = <T>(relativeUrl: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>();

  const update = useCallback(
    async (body?: T) => {
      try {
        setLoading(true);

        await API.put(`${relativeUrl}`, { body });
        setLoading(false);
      } catch (error) {
        logger.error(error);

        setError(error as Error);
        setLoading(false);

        throw error;
      }
    },
    [setLoading, setError, relativeUrl]
  );

  return { loading, error, update };
};

export const useFindOneLazyApi = <T extends Object>(
  relativeUrl: string,
  options?: {
    clearImmediatelyOnReload?: boolean;
  }
) => {
  const [data, setData] = useState<T | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>();

  const clear = useCallback(() => {
    setError(undefined);
    setData(undefined);
    setLoading(false);
  }, [setError, setData, setLoading]);

  const load = useCallback(
    async (id: string | number) => {
      try {
        setError(undefined);
        setLoading(true);

        if (options?.clearImmediatelyOnReload) {
          setData(undefined);
        }

        const data = await API.get(`${relativeUrl}/${id}`);

        setData(data);
      } catch (error) {
        logger.error(error);

        setError(error as Error);
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setError, setData, relativeUrl, options]
  );

  return { data, loading, error, load, clear };
};

export const useLazyApi = <T extends Object, Q>(
  relativeUrl: string,
  options?: {
    clearImmediatelyOnReload?: boolean;
  }
) => {
  const [data, setData] = useState<T | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>();

  const clear = useCallback(() => {
    setError(undefined);
    setData(undefined);
    setLoading(false);
  }, [setError, setData, setLoading]);

  const load = useCallback(
    async (queryParams?: Q) => {
      try {
        setError(undefined);
        setLoading(true);

        if (options?.clearImmediatelyOnReload) {
          setData(undefined);
        }

        const data = await API.get(
          `${relativeUrl}${buildQueryString(queryParams!)}`
        );

        setData(data);
      } catch (error) {
        logger.error(error);

        setError(error as Error);
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setError, setData, relativeUrl, options]
  );

  return { data, loading, error, load, clear };
};
