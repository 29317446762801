import { Flex, Link } from "@aws-amplify/ui-react";
import { theme } from "../../theme";
import Modal from "../shared/components/Modal";
import UserDataUpdateForm from "../shared/components/UserDataUpdateForm";
import { useUpdateUserData } from "../shared/hooks/useUserData";
import TaskCleaningRatesUpdateForm from "../CleaningSchedule/TaskCleaningRatesUpdateForm";
import { useUpdateTaskCleaningRates } from "../CleaningSchedule/hooks/useTaskCleaningRates";

export default ({
  userId,
  onClose,
  onDelete,
}: {
  userId: number;
  onClose: (updated?: boolean) => void;
  onDelete: () => void;
}) => {
  const { update } = useUpdateUserData(userId);
  const { update: updateCleaningRates } = useUpdateTaskCleaningRates();

  return (
    <>
      <Modal title={"Edit user"} onClose={onClose}>
        <Flex>
          <Flex direction={"column"}>
            <Flex>
              <UserDataUpdateForm
                handleUpdate={(values) => update(values)}
                userId={userId}
                onSaved={() => {
                  onClose(true);
                }}
              ></UserDataUpdateForm>
            </Flex>

            <Flex marginTop={32}>
              <Link
                onClick={async () => {
                  onDelete();
                }}
                color={theme.errorColor}
                grow={1}
              >
                Remove from Service Team
              </Link>
            </Flex>
          </Flex>
          <Flex direction={"column"}>
            <Flex>
              <TaskCleaningRatesUpdateForm
                handleUpdate={(values) =>
                  updateCleaningRates({ ...values, userId })
                }
                userId={userId}
                onSaved={() => {
                  onClose(true);
                }}
              ></TaskCleaningRatesUpdateForm>
            </Flex>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};
