import { Badge, Flex, Heading } from "@aws-amplify/ui-react";
import moment from "moment";
import { MdFlight, MdFlightLand, MdFlightTakeoff } from "react-icons/md";
import TextMuted from "../shared/components/TextMuted";
import { TaskGetResponse } from "../shared/hooks/useTasksApi";
import { useTranslation } from "react-i18next";
import { CoreReservationKeySummary } from "../shared/types/core-reservation";
import i18n from "../../i18n/i18n";
import { useCurrentUser } from "../shared/hooks/useCurrentUser";

const ReservationKeySummaryText = ({
  reservation,
}: {
  reservation: CoreReservationKeySummary;
}) => {
  moment.locale(i18n.language);

  return (
    <>
      {`${moment(reservation.arrival_date).format("D MMM")} - ${moment(
        reservation.departure_date
      ).format("D MMM")} · ${reservation.no_of_guests} guests · ${moment(
        reservation.departure_date
      ).diff(reservation.arrival_date, "days")} nights`}
    </>
  );
};

export default function TaskConnectedReservations({
  data,
}: {
  data: TaskGetResponse;
}) {
  const { t } = useTranslation();
  const { isCoordinatorOrMore } = useCurrentUser();

  return (
    <Flex direction="column">
      <Flex direction={"column"} justifyContent={"center"}>
        <Flex alignItems={"center"}>
          <MdFlight></MdFlight>
          <Heading>{t("Service.CurrentReservation")}</Heading>
        </Flex>

        {!data.currentReservation && (
          <Flex alignItems={"center"}>
            <TextMuted>{t("Service.NoCurrentReservation")}</TextMuted>
          </Flex>
        )}

        {data.currentReservation && (
          <Flex alignItems={"center"}>
            <TextMuted>
              <ReservationKeySummaryText
                reservation={data.currentReservation}
              />
            </TextMuted>
          </Flex>
        )}
      </Flex>

      <Flex direction={"column"} justifyContent={"center"}>
        <Flex alignItems={"center"}>
          <MdFlightTakeoff></MdFlightTakeoff>
          <Heading>{t("Service.DepartingReservation")}</Heading>
        </Flex>

        {!data.departingReservation && (
          <Flex alignItems={"center"}>
            <TextMuted>{t("Service.NoDeparturesOnThisDay")}</TextMuted>
          </Flex>
        )}

        {data.departingReservation && isCoordinatorOrMore && (
          <Flex alignItems={"center"}>
            {data.departingReservation.departure_date ===
              data.scheduled_date && (
              <Badge variation="warning">{t("Service.SameDayTurnOver")}</Badge>
            )}
            <TextMuted>
              <ReservationKeySummaryText
                reservation={data.departingReservation}
              />
            </TextMuted>
          </Flex>
        )}
      </Flex>
      <Flex direction={"column"} justifyContent={"center"}>
        <Flex alignItems={"center"}>
          <MdFlightLand></MdFlightLand>
          <Heading>{t("Service.NextReservation")}</Heading>
        </Flex>

        {!data.nextReservation && (
          <Flex alignItems={"center"}>
            <TextMuted>{t("Service.NoNextReservations")}</TextMuted>
          </Flex>
        )}

        {data.nextReservation && (
          <Flex alignItems={"center"}>
            {data.nextReservation.arrival_date === data.scheduled_date && (
              <Badge variation="warning">{t("Service.SameDayTurnOver")}</Badge>
            )}
            <TextMuted>
              <ReservationKeySummaryText reservation={data.nextReservation} />
            </TextMuted>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}
