import { SelectField } from "@aws-amplify/ui-react";
import { useServiceLocations } from "../shared/hooks/service/useServiceLocations";

export default ({
  onChange,
  value,
}: {
  value?: string;
  onChange: (serviceRegion: string) => void;
}) => {
  const { data } = useServiceLocations();

  return (
    <SelectField
      label="Choose Work Region"
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
    >
      <option value={""}></option>
      {data?.locations.map((loc) => (
        <option value={loc.id}>{loc.label}</option>
      ))}
    </SelectField>
  );
};
