import {
  Alert,
  Badge,
  Divider,
  Flex,
  Image,
  Text,
} from "@aws-amplify/ui-react";
import { groupBy } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { logger } from "../../logger";
import Headline from "../shared/components/Headline";
import {
  CoreCoordinationDraftTask,
  CoreCoordinationTask,
  TaskProgressStatus,
} from "../shared/hooks/core-coordination-task";
import { useTasksApi } from "../shared/hooks/useTasksApi";
import { dateToYYMMDD } from "../shared/utils/dates.util";
import AddTaskButton from "./AddTaskButton";
import AddTaskModal from "./AddTaskModal";
import CleaningSchedulePlaceholder from "./CleaningSchedulePlaceholder";
import CitySelector from "./components/CitySelector";
import DaySelector from "./components/DaySelector";
import TaskCard from "./components/TaskCard";
import ScheduleColumn from "./ScheduleColumn";
import TaskDetailsModal from "./TaskDetailsModal";
import HelpButton from "../shared/components/HelpButton";
import i18n from "../../i18n/i18n";
import { useTranslation } from "react-i18next";
import TextMuted from "../shared/components/TextMuted";

export default function CleaningSchedulePage() {
  // use "en" locale in admin pages
  moment.locale(i18n.language);

  const { t } = useTranslation();
  const { data, loading, error, load } = useTasksApi();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentDate, setDate] = useState(
    searchParams.get("date") ? moment(searchParams.get("date")) : moment()
  );
  const [selectedCity, setSelectedCity] = useState<string | undefined>(
    searchParams.has("city") ? String(searchParams.get("city")) : undefined
  );
  const [selectedDraft, setSelectedDraft] = useState<
    CoreCoordinationDraftTask | undefined
  >();

  const [selectedTask, setSelectedTask] = useState<
    Pick<CoreCoordinationTask, "task_id"> | undefined
  >(
    searchParams.get("taskId")
      ? { task_id: searchParams.get("taskId")! }
      : undefined
  );

  useEffect(() => {
    load({ date: dateToYYMMDD(currentDate), location: selectedCity });
  }, [load, currentDate, selectedCity]);

  useEffect(() => {
    if (!searchParams.get("taskId")) {
      setSelectedTask(undefined);
    }

    if (!selectedCity) {
      searchParams.delete("city");
    }

    if (selectedCity) {
      searchParams.set("city", selectedCity);
    }

    if (currentDate) {
      searchParams.set("date", currentDate.format("YYYY-MM-DD"));
    }

    setSearchParams(searchParams);
  }, [
    currentDate,
    selectedCity,
    setSelectedTask,
    setSearchParams,
    load,
    searchParams,
  ]);

  const tasks = useMemo(() => {
    if (!data) {
      return;
    }

    const timeGroups = groupBy(data.tasks, "scheduled_time");

    return {
      timeGroups,
    };
  }, [data]);

  const handleTaskSelection = useCallback(
    (task: CoreCoordinationDraftTask | CoreCoordinationTask) => {
      if ((task as CoreCoordinationDraftTask)?.is_system_proposed) {
        logger.debug(`Using draft task`, task);

        setSelectedDraft(task as CoreCoordinationDraftTask);
      } else {
        setSearchParams({ ...searchParams, taskId: task.task_id });
        setSelectedTask(task);
      }
    },
    [setSelectedDraft, searchParams, setSelectedTask, setSearchParams]
  );

  return (
    <>
      <Flex direction={"column"} grow={1} overflow={"hidden"}>
        <Flex marginBottom={12} justifyContent={"space-between"}>
          <Headline>{t("Service.CleaningSchedule")}</Headline>

          <Flex>
            <HelpButton
              url={
                "https://writer.zoho.eu/writer/published/4z1lxa7a056e66cf346918c5cdd64c74ba63c"
              }
            ></HelpButton>
            <AddTaskButton
              onCreated={(taskId) => {
                load({
                  date: dateToYYMMDD(currentDate),
                  location: selectedCity,
                });
                setSelectedDraft(undefined);
                setSelectedTask({ task_id: taskId });
              }}
            ></AddTaskButton>
          </Flex>
        </Flex>

        <DaySelector currentDate={currentDate} setDate={setDate} />

        {error && (
          <Flex>
            <Alert
              grow={1}
              variation="error"
              isDismissible={false}
              hasIcon={true}
            >
              {error.message}
            </Alert>
          </Flex>
        )}

        {data && (
          <Flex overflow={"scroll"}>
            <CitySelector
              selectedCity={selectedCity}
              setSelectedCity={setSelectedCity}
            />
          </Flex>
        )}

        {loading && <CleaningSchedulePlaceholder />}

        {!loading && tasks && (
          <>
            {data && (
              <>
                {data.tasks.length > 0 && (
                  <>
                    <Flex marginTop={16} alignItems={"center"}>
                      <Text fontWeight={"bold"}>{t("Service.Schedule")}</Text>
                      <Badge>Tasks {data.tasks.length}</Badge>
                    </Flex>
                  </>
                )}
                <Divider></Divider>
                {data.tasks.length === 0 && (
                  <Flex
                    direction={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Image
                      margin="0 auto"
                      width={400}
                      src={"/service/service-no-items.jpg"}
                      alt={t("Service.NoTasksAssigned")}
                    ></Image>
                    <TextMuted>{t("Service.NoTasksForSelection")}</TextMuted>
                  </Flex>
                )}

                {!!data.tasks.length && (
                  <Flex
                    grow={1}
                    direction={"row"}
                    justifyContent={"flex-start"}
                    overflow={"scroll"}
                  >
                    <ScheduleColumn
                      onClick={(task) => {
                        handleTaskSelection(task);
                      }}
                      tasks={[
                        ...(tasks.timeGroups["undefined"] || []),
                        ...(tasks.timeGroups["null"] || []),
                      ].sort((a, b) => {
                        return a.progress_status === TaskProgressStatus.Planned
                          ? 0
                          : -1;
                      })}
                      label={"All day"}
                    ></ScheduleColumn>

                    {Object.keys(tasks.timeGroups)
                      .sort()
                      .filter(
                        (timeGroup) =>
                          timeGroup !== "undefined" && timeGroup !== "null"
                      )
                      .map((timeGroup) => (
                        <Flex
                          direction={"column"}
                          grow={1}
                          basis={0}
                          key={`group-${timeGroup}`}
                        >
                          <Text marginBottom={16}>
                            {timeGroup}{" "}
                            <Badge>{tasks.timeGroups[timeGroup].length}</Badge>
                          </Text>
                          <Flex direction={"column"} margin={8}>
                            {tasks.timeGroups[timeGroup].map((task, index) => (
                              <TaskCard
                                onClick={() => handleTaskSelection(task)}
                                key={task.task_id}
                                task={task}
                              />
                            ))}
                          </Flex>
                        </Flex>
                      ))}
                  </Flex>
                )}
              </>
            )}
          </>
        )}
      </Flex>

      {selectedTask && (
        <TaskDetailsModal
          taskId={selectedTask.task_id}
          onChanged={() => {
            load({ date: dateToYYMMDD(currentDate), location: selectedCity });
          }}
          onClose={() => {
            setSelectedTask(undefined);

            searchParams.delete("taskId");
            setSearchParams({ ...searchParams });
          }}
        ></TaskDetailsModal>
      )}

      {selectedDraft && (
        <AddTaskModal
          onCreated={(taskId) => {
            load({ date: dateToYYMMDD(currentDate), location: selectedCity });
            setSelectedDraft(undefined);
            setSelectedTask({ task_id: taskId });
          }}
          draft={selectedDraft}
          onClose={() => {
            setSelectedDraft(undefined);

            searchParams.delete("taskId");
            setSearchParams({ ...searchParams });
          }}
        ></AddTaskModal>
      )}
    </>
  );
}
