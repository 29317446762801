import { Button, Flex, TextField } from "@aws-amplify/ui-react";
import { useState } from "react";
import { logger } from "../../logger";
import Modal from "../shared/components/Modal";
import { useInviteNewServiceUser } from "../shared/hooks/useInviteNewUser";
import { validateEmail } from "../shared/utils/validateEmail";
import { CognitoGroup } from "../shared/hooks/useUserData";
import WorkRegionSelectField from "./WorkRegionSelectField";

interface InviteUserFormValues {
  email: string;
  firstName: string;
  lastName: string;
  serviceRegion: string;
  group: CognitoGroup;
}

export default ({ onClose }: { onClose: () => void }) => {
  const { loading: submitting, create: inviteNewServiceUser } =
    useInviteNewServiceUser();
  const [formValues, setFormValues] = useState<InviteUserFormValues>({
    email: "",
    firstName: "",
    lastName: "",
    serviceRegion: "",
    group: CognitoGroup.Service,
  });

  const validate = (formValues: InviteUserFormValues) => {
    if (!formValues.email || !validateEmail(formValues.email)) {
      return false;
    }

    if (!formValues.firstName) {
      return false;
    }

    if (!formValues.lastName) {
      return false;
    }

    if (!formValues.serviceRegion) {
      return false;
    }

    return true;
  };

  return (
    <>
      <Modal
        title={"Invite new user to join Service Team"}
        onClose={onClose}
        hideCloseBtn={true}
      >
        <Flex direction={"column"} gap={16}>
          <Flex direction={"column"} gap={0}>
            <WorkRegionSelectField
              value={formValues.serviceRegion}
              onChange={(newValue) => {
                setFormValues({ ...formValues, serviceRegion: newValue });
              }}
            ></WorkRegionSelectField>
          </Flex>

          <Flex direction={"column"} gap={0}>
            <TextField
              type="email"
              label={"E-mail"}
              value={formValues.email}
              onChange={(event) => {
                setFormValues({
                  ...formValues,
                  email: event.currentTarget.value,
                });
              }}
            ></TextField>
          </Flex>

          <Flex direction={"column"} gap={0}>
            <TextField
              type="text"
              label={"First name"}
              value={formValues.firstName}
              onChange={(event) => {
                setFormValues({
                  ...formValues,
                  firstName: event.currentTarget.value,
                });
              }}
            ></TextField>
          </Flex>

          <Flex direction={"column"} gap={0}>
            <TextField
              type="text"
              label={"Last name"}
              value={formValues.lastName}
              onChange={(event) => {
                setFormValues({
                  ...formValues,
                  lastName: event.currentTarget.value,
                });
              }}
            ></TextField>
          </Flex>
        </Flex>

        <Flex marginTop={32}>
          <Button
            onClick={async () => {
              if (validate(formValues)) {
                const result = await inviteNewServiceUser(formValues);

                logger.debug(result);

                onClose();
              }
            }}
            isLoading={submitting}
            isDisabled={!validate(formValues) || submitting}
            variation="primary"
            grow={1}
            disabled={!validate(formValues) || submitting}
          >
            Invite New User
          </Button>
        </Flex>
      </Modal>
    </>
  );
};
