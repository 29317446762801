import {
  Badge,
  Card,
  Flex,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@aws-amplify/ui-react";
import { useEffect, useMemo } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { MdCheck, MdEdit, MdRemove, MdWarning } from "react-icons/md";
import { theme } from "../../theme";
import ContractDetailsLink from "../Contracts/components/ContractDetailsLink";
import Headline from "../shared/components/Headline";
import LoaderBox from "../shared/components/LoaderBox";
import Stats from "../shared/components/Stats";
import { columns } from "./columns";
import Filters from "../shared/components/Filters";
import { useSearchParams } from "react-router-dom";
import { useSearchParamsUtils } from "../shared/hooks/useSearchParamsUtils";
import { useDestinations } from "../shared/hooks/useDestinations";
import { useLazyProperties } from "../shared/hooks/useContractProperties";

export default () => {
  const { data: properties, load, loading } = useLazyProperties();
  const [searchParams] = useSearchParams();
  const { setSingleSearchParam } = useSearchParamsUtils();
  const { data: destinationsData } = useDestinations();

  useEffect(() => {
    if (!searchParams.get("status")) {
      return setSingleSearchParam("status", "active", true);
    }

    load({
      status: searchParams.get("status") as
        | "active"
        | "all"
        | "disabled"
        | "unassigned"
        | null,
      city: searchParams.get("city")!,
    });
  }, [searchParams, load, setSingleSearchParam]);

  const stats = useMemo(() => {
    if (!properties?.length) {
      return [];
    }

    return [
      {
        value: properties.length,
        name: "Properties",
      },
      {
        value: properties.filter((item) => !item.booking_url).length,
        name: "Missing BookingCom URL",
      },
      {
        value: properties.filter((item) => !item.airbnb_url).length,
        name: "Missing Airbnb URL",
      },
    ];
  }, [properties]);

  return (
    <Flex direction={"column"} overflow={"hidden"} grow={1}>
      <Flex>
        <Headline>Properties</Headline>
      </Flex>

      <Flex grow={1} padding={1}>
        <Card variation="elevated" grow={1}>
          <Filters
            loading={loading}
            filters={[
              {
                label: "City",
                key: "city",
                options: [
                  { label: "All", value: "" },
                  ...(destinationsData?.destinations || []).map((item) => ({
                    value: item.label,
                    label: item.label,
                  })),
                ],
              },
              {
                label: "Status",
                key: "status",
                options: [
                  {
                    value: "active",
                    label: "Active",
                  },
                  {
                    value: "unassigned",
                    label: "Unassigned",
                  },
                  {
                    value: "all",
                    label: "All",
                  },
                  {
                    value: "disabled",
                    label: "Disabled",
                  },
                ],
              },
            ]}
          ></Filters>
        </Card>
      </Flex>

      <Flex>{loading && <LoaderBox></LoaderBox>}</Flex>

      {!loading && properties && (
        <Flex direction={"column"}>
          <Flex overflow={"scroll"} padding={1}>
            <Stats stats={stats} />
          </Flex>
          <Flex overflow={"scroll"}>
            <Table caption="" highlightOnHover={false}>
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell
                      key={`column-${index}`}
                      as="th"
                      textAlign={column.textAlign || "center"}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {(properties || []).map((property) => (
                  <TableRow
                    key={`row-${property.internal_id}`}
                    backgroundColor={
                      !property.airbnb_url || !property.booking_url
                        ? theme.accentColor
                        : undefined
                    }
                    textDecoration={
                      property.disabled ? "line-through" : undefined
                    }
                  >
                    <TableCell>{property.internal_id}</TableCell>

                    <TableCell>
                      <Link
                        isExternal={true}
                        href={`https://dashboard.hostaway.com/listing/${property.hostaway_id}`}
                      >
                        {property.hostaway_id}
                      </Link>
                    </TableCell>

                    <TableCell>{property.name}</TableCell>

                    <TableCell>{property.address}</TableCell>

                    <TableCell textAlign="center">
                      {property.assigned_mgmt_contracts?.length > 1 ? (
                        <OverlayTrigger
                          overlay={
                            <Tooltip>
                              Multiple Management Contracts assigned to this
                              property
                            </Tooltip>
                          }
                        >
                          <div>
                            <MdWarning color="red" size={30} />
                          </div>
                        </OverlayTrigger>
                      ) : property.disabled ? (
                        <MdRemove size={30} color="grey" />
                      ) : (
                        <MdCheck size={30} color="green" />
                      )}
                    </TableCell>

                    <TableCell>
                      <Flex>
                        {property.is_mgmt_property && (
                          <Badge variation="success">Mgmt</Badge>
                        )}
                        {property.is_invest_property && (
                          <Badge variation="info">Invest</Badge>
                        )}
                      </Flex>
                    </TableCell>

                    <TableCell>
                      {[
                        ...(property.assigned_mgmt_contracts || []).map(
                          (id) => ["mgmt", id]
                        ),
                        ...(property.assigned_invest_contracts || []).map(
                          (id) => ["invest", id]
                        ),
                      ].map(([type, id], index, arr) => (
                        <Flex gap={6}>
                          <Link
                            key={index}
                            isExternal={true}
                            href={`/contract/details/${type}/${id}`}
                          >
                            {`${type}-${id}`}
                          </Link>
                        </Flex>
                      ))}
                    </TableCell>

                    <TableCell>
                      {property.booking_url ? (
                        <Link
                          isExternal={true}
                          href={property.booking_url as string}
                        >
                          Booking
                        </Link>
                      ) : (
                        "-"
                      )}
                    </TableCell>

                    <TableCell>
                      {property.airbnb_url ? (
                        <Link
                          isExternal={true}
                          href={property.airbnb_url as string}
                        >
                          Airbnb
                        </Link>
                      ) : (
                        "-"
                      )}
                    </TableCell>

                    <TableCell>
                      <Flex>
                        {(property.assigned_invest_contracts ||
                          property.assigned_mgmt_contracts) && (
                          <ContractDetailsLink
                            contractType={
                              property.is_invest_property ? "invest" : "mgmt"
                            }
                            contractId={
                              property.is_invest_property
                                ? property.assigned_invest_contracts[0]
                                : property.assigned_mgmt_contracts[0]
                            }
                          ></ContractDetailsLink>
                        )}

                        <Link
                          textDecoration="underline dotted"
                          isExternal
                          href={`/property/details?id=${property.internal_id}`}
                        >
                          <MdEdit />
                        </Link>
                      </Flex>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
