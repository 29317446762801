import { useLazyApi, useUpdateApi } from "../../shared/hooks/useLazyApi";

export enum CleaningSizeCategory {
  STUDIO = "studio",
  APARTMENT = "apartment",
  SUITE = "suite",
  BIG_SUITE = "bigSuite",
}

export type TaskCleaningRate = {
  user_id: number;
  amount_brutto: number;
  size_category: CleaningSizeCategory;
  id: number;
};

type TaskCleaningRatesUpdateInput = {
  userId: number;
  studio: number;
  apartment: number;
  suite: number;
  bigSuite: number;
};

export const useTaskCleaningRates = () =>
  useLazyApi<{ rates: TaskCleaningRate[] }, { userId: number }>(
    `/v1/admin/task-cleaning-rates`
  );

export const useUpdateTaskCleaningRates = () =>
  useUpdateApi<TaskCleaningRatesUpdateInput>(`/v1/admin/task-cleaning-rates`);
