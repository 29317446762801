import {
  Flex,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@aws-amplify/ui-react";
import { MdEdit, MdOutlineMenu } from "react-icons/md";
import PdfColumn from "../Docs/PdfColumn";
import ExternalLink from "./components/ExternalLink";
import LoaderBox from "./components/LoaderBox";
import { ReportTable } from "./hooks/common";
import { formatHeader } from "./utils/formatHeader";
import StatusIndicator from "../Properties/StatusIndicator";
import DisplayRecordsCount from "./components/DisplayRecordsCount";
import moneyFormatter from "./utils/moneyFormatter";

export default ({
  columnComponents,
  data,
  loading,
  onSelected,
  detailsUrl,
}: {
  columnComponents?: {
    [columnName: string]: (props: any) => JSX.Element;
  };
  detailsUrl?: (item: any) => string;
  loading: boolean;
  data?: ReportTable<any, any>;
  onSelected?: (item: any) => void;
}) => {
  const createSumCell = (sum: any, sumType: keyof any) => (
    <TableCell fontWeight={600} textAlign="left">
      {Object.keys(data?.sums[sumType]).map((sumKey) => (
        <Flex key={"sums" + sumKey}>
          {moneyFormatter(data?.sums[sumType][sumKey])} {sumKey}
        </Flex>
      ))}
    </TableCell>
  );

  return (
    <Flex direction={"column"} grow={1} overflow={"scroll"}>
      {data && !loading && <DisplayRecordsCount count={data.items.length} />}

      <Flex>{loading && <LoaderBox></LoaderBox>}</Flex>

      {!loading && data && (
        <Flex direction={"column"}>
          <Table caption="" highlightOnHover={false}>
            <TableHead>
              <TableRow>
                {data.columns.map((column, index) => (
                  <TableCell key={`column-${index}`} as="th">
                    {formatHeader(column.fieldKey as string)}
                  </TableCell>
                ))}

                {onSelected && <TableCell key="action-section"></TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.sums && (
                <TableRow key={"sums"} boxShadow={"large"}>
                  {data.columns.map((column, index) =>
                    data.sums[column.fieldKey!] ? (
                      createSumCell(data.sums, column.fieldKey!)
                    ) : (
                      <TableCell
                        key={`sum-cell-${index}`}
                        textAlign="left"
                      ></TableCell>
                    )
                  )}
                </TableRow>
              )}

              {(data.items || []).map((row, index) => (
                <TableRow key={`row-${index}`}>
                  {data.columns.map((column, index) => (
                    <TableCell key={`head-column-${index}`} textAlign="left">
                      {column.type === "ValidationResult" && (
                        <StatusIndicator
                          ok={!!row[column.fieldKey!]}
                        ></StatusIndicator>
                      )}

                      {column.type === "Id" && (
                        <ExternalLink
                          label={row[column.fieldKey!]}
                          href={`/?q=${row[column.fieldKey!]}`}
                        ></ExternalLink>
                      )}

                      {column.type === "PropertyId" && (
                        <ExternalLink
                          label={row[column.fieldKey!]}
                          href={`/property/details?id=${row[column.fieldKey!]}`}
                        ></ExternalLink>
                      )}

                      {column.type === "PdfFile" && (
                        <PdfColumn fileKey={row[column.fieldKey!]}></PdfColumn>
                      )}

                      {column.type === "HostawayListingId" && (
                        <ExternalLink
                          label={row[column.fieldKey!]}
                          href={`https://dashboard.hostaway.com/listing/edit/${
                            row[column.fieldKey!]
                          }`}
                        ></ExternalLink>
                      )}

                      {!column.type &&
                        columnComponents &&
                        columnComponents[column.fieldKey as string] &&
                        columnComponents[column.fieldKey as string](row)}

                      {!column.type &&
                        !(columnComponents || {})[column.fieldKey as string] &&
                        row[column.fieldKey!]}
                    </TableCell>
                  ))}

                  {onSelected && (
                    <TableCell onClick={() => onSelected(row)}>
                      <Flex justifyContent={"flex-end"}>
                        <MdOutlineMenu></MdOutlineMenu>
                      </Flex>
                    </TableCell>
                  )}

                  {detailsUrl && (
                    <TableCell>
                      <Link
                        isExternal={true}
                        href={detailsUrl(row)}
                        textDecoration="underline dotted"
                      >
                        <MdEdit />
                      </Link>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Flex>
      )}
    </Flex>
  );
};
