import { useEffect, useState } from "react";

export default function useForm<FormValues>(
  defaultValues: FormValues,
  options?: { validate?: (values: FormValues) => boolean }
) {
  const [dirty, setDirty] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<FormValues>(defaultValues);
  const [valid, setValid] = useState<boolean>(false);

  useEffect(() => {
    setDirty(false);
  }, [defaultValues]);

  const setFieldValue = (
    fieldKey: keyof FormValues,
    fieldValue: string | number | undefined | null
  ) => {
    setFormValues({
      ...formValues,
      [fieldKey]: fieldValue,
    });

    setDirty(true);

    if (options?.validate) {
      setValid(options.validate(formValues));
    } else {
      setValid(true);
    }
  };

  return {
    dirty,
    setDirty,
    formValues,
    setFieldValue,
    setFormValues,
    valid,
  };
}
