import { Flex, TextField } from "@aws-amplify/ui-react";
import { useCallback } from "react";
import { debounce } from "lodash";

export default ({
  placeholder,
  onSearch,
}: {
  placeholder?: string;
  onSearch?: (queryString: string) => void;
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce((queryString: string) => {
      if (onSearch && (queryString.length > 2 || !queryString)) {
        onSearch(queryString);
      }
    }, 1000),
    [onSearch]
  );

  return (
    <Flex direction={"column"}>
      <Flex direction="column" grow={1}>
        <Flex direction="row" justifyContent={"space-between"}>
          <Flex direction={"row"}>
            <Flex direction={"column"}>
              <TextField
                minWidth={240}
                size="small"
                gap={0}
                height={34}
                label={""}
                placeholder={placeholder || "Search..."}
                onChange={(event) => handleSearch(event.target.value)}
              ></TextField>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
