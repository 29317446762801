import { Divider, Flex } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
import { MdContentPaste, MdReport } from "react-icons/md";
import SideMenuLink from "../shared/components/SideMenuLink";
import TextMuted from "../shared/components/TextMuted";

export default () => {
  const { t } = useTranslation();

  return (
    <>
      <Flex direction="column">
        <TextMuted>{t("Service.ServiceCockpit")}</TextMuted>
        <Divider></Divider>
      </Flex>

      <SideMenuLink
        label={t("Service.MyTasks")}
        icon={<MdContentPaste />}
        href="/service/rep/schedule"
      ></SideMenuLink>

      <SideMenuLink
        label={t("Service.MyReport")}
        icon={<MdReport />}
        href="/service/rep/report"
      ></SideMenuLink>
    </>
  );
};
