import { Flex } from "@aws-amplify/ui-react";
import { usePricing } from "./usePricing";
import GenericTable from "../shared/GenericTable";
import PriceTextField from "./PriceTextField";
import { Link } from "react-router-dom";

export default () => {
  const { data, loading } = usePricing();

  return (
    <Flex direction="column">
      {data && (
        <GenericTable
          loading={loading}
          columnComponents={{
            name: (item) => {
              return (
                <Link to={`property/${item.internal_id}`}>{item.name}</Link>
              );
            },
            min_price: (item) => {
              return (
                <PriceTextField
                  name={"min_price"}
                  propertyId={item.internal_id}
                  initialValue={item.min_price}
                ></PriceTextField>
              );
            },
            base_price: (item) => {
              return (
                <PriceTextField
                  name={"base_price"}
                  propertyId={item.internal_id}
                  initialValue={item.base_price}
                ></PriceTextField>
              );
            },
          }}
          data={{
            sums: {},
            items: data,
            columns: [
              {
                fieldKey: "name",
              },
              {
                fieldKey: "hostaway_id",
              },
              {
                fieldKey: "min_price",
              },
              {
                fieldKey: "base_price",
              },
            ],
          }}
        ></GenericTable>
      )}
    </Flex>
  );
};
