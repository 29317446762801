import { updateCurrentUserData } from "../hooks/useCurrentUser";
import Modal from "./Modal";
import UserDataUpdateForm from "./UserDataUpdateForm";
import { useUserProfile } from "./UserProfileProvider";

export default ({ onClose }: { onClose: () => void }) => {
  const { userProfile } = useUserProfile();

  return (
    <>
      <Modal
        title={"Complete your profile"}
        onClose={onClose}
        hideCloseBtn={true}
      >
        <>
          {userProfile && (
            <UserDataUpdateForm
              handleUpdate={async (values) => {
                await updateCurrentUserData({
                  phone: values.phone,
                  first_name: values.first_name,
                  last_name: values.last_name,
                  service_region: values.service_region,
                });
              }}
              userId={userProfile.id}
              onSaved={onClose}
            ></UserDataUpdateForm>
          )}
        </>
      </Modal>
    </>
  );
};
