import {
  Button,
  Flex,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Text,
} from "@aws-amplify/ui-react";
import moment from "moment";
import { Dispatch, SetStateAction } from "react";
import { MdDone, MdDoneAll, MdDownload, MdWarning } from "react-icons/md";
import { theme } from "../../../theme";
import ContractDetailsLink from "../../Contracts/components/ContractDetailsLink";
import ExternalLink from "../../shared/components/ExternalLink";
import { useTableFilter } from "../../shared/components/tables/useTableFiltering";
import { IStatementProposal } from "../../shared/hooks/useOwnerStatement";
import { downloadFile } from "../../shared/utils/downloadFile";
import { ProposalStateResolver } from "./AdminPayoutHelpers";
import { columns } from "./columns";
import { PayoutStatus } from "../../shared/hooks/useCoreData";

export interface UIIStatementProposal extends IStatementProposal {
  inEdit: boolean;
}

interface PayoutPlanningTableProps {
  proposals: UIIStatementProposal[];
  getProposalState: ProposalStateResolver;
  setSelectedRow: Dispatch<SetStateAction<UIIStatementProposal | undefined>>;
}

export default function PayoutPlanningTable({
  proposals,
  setSelectedRow,
  getProposalState,
}: PayoutPlanningTableProps) {
  const { rowData, sortDataFn } = useTableFilter(proposals);

  const formatDate = (date: Date) => moment(date).format("YYYY-MM-DD");

  const createCheckIcon = (isPlausible: boolean) =>
    isPlausible ? "-" : <MdWarning size={30} color="red" />;

  const createTaxIcon = (value: boolean) =>
    value ? <MdDone size={30} color="red" /> : "-";

  const createApprovedIcon = (status: UIIStatementProposal["status"]) =>
    status === "approved" || status === "planned" ? (
      <MdDone size={30} color="green" />
    ) : (
      <>-</>
    );

  const createPublishedStatusIcon = (
    status: UIIStatementProposal["published_status"]
  ) =>
    ({
      None: "-",
      Planned: <MdDone size={30} color="green" />,
      Paid: <MdDoneAll size={30} color="green" />,
    }[status ?? "None"]);

  const createDownloadIcon = (url: string) => (
    <Link
      onClick={async () => {
        await downloadFile(url);
      }}
      textDecoration="underline dotted"
      isExternal={true}
    >
      <MdDownload size={30} color={theme.primaryColor} />
    </Link>
  );

  const createActionButton = (item: UIIStatementProposal) => {
    const resolved = getProposalState(item);

    return (
      <Button
        isLoading={item.inEdit}
        size="small"
        variation={resolved === "Paid" ? undefined : "primary"}
        onClick={() => setSelectedRow(item)}
      >
        {resolved === "Planned" || resolved === "Paid"
          ? "Details"
          : resolved === "NeedsApproval"
          ? "Approve"
          : resolved === "ReadyForPublish"
          ? "Details"
          : "Invalid State"}
      </Button>
    );
  };

  const createTableRow = (item: UIIStatementProposal, index: number) => {
    // WE MUST RENDER ALL PAYOUT PROPOSALS, ALSO the 0 ONES! @ADRIAN DO NOT TOUCH THIS!
    // // we need to create payout proposals also for those having 0 payout value,
    // // because otherwise they will be reported as missing settlements for the period,
    // // but we are not rendering them

    let backgroundColor = "transparent";

    if (
      item.published_status === PayoutStatus.Planned &&
      parseFloat(item.prorated_payout || "0") > 0
    ) {
      backgroundColor = "transparent";
    }

    if (item.published_status === null && item.status !== "approved") {
      backgroundColor = theme.accentColor;
    }

    return (
      <TableRow
        key={index}
        backgroundColor={backgroundColor}
        overflow={"scroll"}
      >
        <TableCell textAlign="left">{item.statement_title}</TableCell>

        <TableCell textAlign="left">
          <ExternalLink
            label={item.account_mail}
            href={`/users/details?userId=${item.account_id}`}
          ></ExternalLink>
        </TableCell>

        <TableCell textAlign="left">
          <Text>{formatDate(item.start_date_statement)}</Text>
          <Text>{formatDate(item.end_date_statement)}</Text>
        </TableCell>

        <TableCell textAlign="right">{item.payout_base} PLN</TableCell>

        <TableCell textAlign="right">{item.total_expenses} PLN</TableCell>

        <TableCell textAlign="right">
          {item.utility_cost} {item.utility_cost ? "PLN" : "n/a"}
        </TableCell>

        <TableCell textAlign="right" style={{ fontWeight: 700 }}>
          {item.prorated_payout} PLN
        </TableCell>

        <TableCell textAlign="center">
          {createDownloadIcon(item.pdf_url)}
        </TableCell>

        <TableCell textAlign="center">
          {createTaxIcon(Boolean(item.vat_id))}
        </TableCell>

        <TableCell textAlign="center">
          {createCheckIcon(item.passed_plausibility)}
        </TableCell>

        <TableCell textAlign="center">
          {createApprovedIcon(item.status)}
        </TableCell>

        <TableCell textAlign="center">
          {createPublishedStatusIcon(item.published_status)}
        </TableCell>

        <TableCell textAlign="center">
          <Flex
            direction={"row"}
            gap={30}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <Flex>
              {Number(item.prorated_payout) > 0
                ? createActionButton(item)
                : "-"}
            </Flex>

            <Flex>
              <ContractDetailsLink
                contractType={item.contract_type}
                contractId={item.contract_id}
              ></ContractDetailsLink>
            </Flex>
          </Flex>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Flex>
      <Table
        size="small"
        caption=""
        highlightOnHover={false}
        overflow={"scroll"}
      >
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell
                key={`column-${index}`}
                as="th"
                textAlign={column.textAlign || "left"}
                onClick={() =>
                  column.fieldKey
                    ? sortDataFn(column.fieldKey, column.type)
                    : undefined
                }
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{rowData.map(createTableRow)}</TableBody>
      </Table>
    </Flex>
  );
}
