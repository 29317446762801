import { Flex, Heading, TextAreaField } from "@aws-amplify/ui-react";
import Loader from "../shared/components/Loader";
import { useTranslation } from "react-i18next";

export default ({
  defaultValue,
  required,
  onChange,
  updating,
  isReadOnly,
}: {
  isReadOnly?: boolean;
  required?: boolean;
  onChange: (value: string) => void;
  updating: boolean;
  defaultValue?: string;
}) => {
  const { t } = useTranslation();

  return (
    <Flex direction={"column"} gap={0}>
      <Heading>
        {t("Service.Notes")} {required ? "*" : ""}
      </Heading>

      <Flex direction={"column"}>
        <Flex boxShadow={"small"} position="relative">
          <TextAreaField
            disabled={isReadOnly}
            isReadOnly={isReadOnly}
            defaultValue={defaultValue}
            grow={1}
            onChange={(event) => {
              onChange(event.target.value);
            }}
            label=""
            placeholder={t("Service.NotesPlaceholder")}
          ></TextAreaField>
          {updating && (
            <Flex position={"absolute"} right={16} top={16}>
              <Loader></Loader>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
