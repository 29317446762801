import { StatItem } from "../components/Stats";
import { CoreReservation } from "../types/core-reservation";
import { HostawayListing } from "../types/hostway-listing";
import { API } from "../utils/API";
import { ReportTable } from "./common";
import {
  CoreCoordinationDraftTask,
  CoreCoordinationTask,
  CoreCoordinationTaskWithImages,
  TaskProgressStatus,
  UpdateCoreCoordinationTaskParams,
} from "./core-coordination-task";
import { useApi } from "./useApi";
import { useCreateApi, useLazyApi, useUpdateApi } from "./useLazyApi";

export interface TaskTemplateRequirements {
  id: number;
  name: string;
  description: string;
  sections: {
    id: number;
    name: string;
    requirements: {
      id: number;
      requirement_type: "checklist" | "yes_no";
      photo_required: boolean;
      actions: {
        name: string;
        id: number;
      }[];
    }[];
  }[];
}

export interface TaskRequirementValue {
  requirement_id: number;
  value: boolean;
  task_id: string;
  timestamp: string;
}

export const getUploadUrlForTask = (taskId: string) =>
  `/v1/admin/tasks/${taskId}/upload`;

export const useTasksApi = () =>
  useLazyApi<
    {
      tasks: (CoreCoordinationTask | CoreCoordinationDraftTask)[];
    },
    { date: string; userId?: number; location?: string }
  >(`/v1/admin/tasks`);

export type TaskGetResponse = CoreCoordinationTask &
  CoreCoordinationTaskWithImages & { user_name: string | null } & {
    listing: Pick<
      HostawayListing,
      "beds_number" | "person_capacity" | "squere_meters"
    >;
    requirements: { total: number; fullfilled: number };
    nextReservation: Pick<
      CoreReservation,
      "arrival_date" | "departure_date" | "no_of_guests"
    >;
    departingReservation: Pick<
      CoreReservation,
      "arrival_date" | "departure_date" | "no_of_guests"
    >;
    currentReservation: Pick<
      CoreReservation,
      "arrival_date" | "departure_date" | "no_of_guests"
    >;
    accessInfo?: { door_code: string; checkin_instructions: string };
  };

export const useTask = (taskId: string) =>
  useApi<TaskGetResponse, undefined>(`/v1/admin/tasks/${taskId}`);

export const useTaskComments = (taskId: string) =>
  useApi<
    { taskId: string; body: string; created_at: string; username: string }[],
    undefined
  >(`/v1/admin/tasks/${taskId}/comments`);

export const useTasksReport = () =>
  useLazyApi<
    ReportTable<CoreCoordinationTask, undefined> & {
      stats: StatItem[];
    },
    { userId: number; start: string; end: string }
  >(`/v1/admin/tasks/report`);

export const useTaskTemplates = () =>
  useLazyApi<
    {
      id: number;
      name: string;
      description: string;
      sections: {
        id: number;
        name: string;
        requirements: {
          id: number;
          requirement_type: "checklist" | "yes_no";
          photo_required: boolean;
          actions: {
            name: string;
            id: number;
          }[];
        }[];
      }[];
    },
    {
      templateId: number;
    }
  >(`/v1/admin/tasks-template`);

export const useTaskActivity = (taskId: string) =>
  useApi<
    {
      task_id: string;
      timestamp: string;
      event: TaskProgressStatus;
      username: string;
    }[],
    undefined
  >(`/v1/admin/tasks/${taskId}/activity`);

export const useTaskCommentsCreate = (taskId: string) =>
  useCreateApi<{ body: string }>(`/v1/admin/tasks/${taskId}/comments`);

export const useTaskUpdate = (taskId: string) =>
  useUpdateApi<UpdateCoreCoordinationTaskParams>(`/v1/admin/tasks/${taskId}`);

export const useTaskRequirementValueUpdate = (taskId: string) =>
  useUpdateApi<Pick<TaskRequirementValue, "requirement_id" | "value">>(
    `/v1/admin/tasks/${taskId}/requirements`
  );

export interface TaskRequirementsValues {
  requirements: TaskRequirementValue[];
  requirementsCount: number;
  fullfilled: number;
}

export const useTaskRequirementValues = (taskId: string) =>
  useLazyApi<TaskRequirementsValues, undefined>(
    `/v1/admin/tasks/${taskId}/requirements`
  );

export const useTaskUpdateProgressStatus = (taskId: string) =>
  useUpdateApi<UpdateCoreCoordinationTaskParams>(
    `/v1/admin/tasks/${taskId}/progress_status`
  );

export const deleteTask = (taskId: string) =>
  API.delete(`/v1/admin/tasks/${taskId}`, {});

export const useTaskCreate = () =>
  useCreateApi<Partial<CoreCoordinationTask>>(`/v1/admin/tasks`);
