import { Button, Flex } from "@aws-amplify/ui-react";
import UserAutocomplete from "../Properties/UserAutocomplete";
import Modal from "../shared/components/Modal";
import { useState } from "react";
import { theme } from "../../theme";
import { addUserToServiceGroup } from "../shared/hooks/service/useServicePeople";
import TextMuted from "../shared/components/TextMuted";

export default ({ onClose }: { onClose: () => void }) => {
  const [userId, setUserId] = useState<undefined | number>();

  return (
    <>
      <Modal title={"Add User to Service Team"} onClose={onClose}>
        <Flex marginBottom={24}>
          <TextMuted>
            This will add an existing user to the service team.
          </TextMuted>
        </Flex>
        <Flex direction={"column"} gap={16}>
          <Flex direction={"column"} gap={0}>
            <UserAutocomplete
              onSave={async (selectedUserId) => {
                setUserId(selectedUserId);
              }}
            ></UserAutocomplete>
          </Flex>
        </Flex>

        <Flex marginTop={32}>
          <Button
            onClick={async () => {
              if (userId) {
                await addUserToServiceGroup(userId);

                onClose();
              }
            }}
            isDisabled={!userId}
            variation="primary"
            backgroundColor={!!userId ? theme.errorColor : undefined}
            grow={1}
            disabled={!userId}
          >
            Add to Service Group
          </Button>
        </Flex>
      </Modal>
    </>
  );
};
