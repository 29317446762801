import { Flex, Text } from "@aws-amplify/ui-react";
import { CoordinationTask } from "../../shared/hooks/core-coordination-task";
import { useMemo } from "react";
import { getColorForTask } from "./getStyleForTaskCard";

export default ({ task }: { task: CoordinationTask }) => {
  const bgColor = useMemo(() => {
    return getColorForTask({ task });
  }, [task]);

  const assignedUserName = useMemo(() => {
    if (!task.user_name) {
      return undefined;
    }

    const splitUserName = task.user_name.split(" ");

    let label = `${splitUserName[0]}`;

    if (splitUserName[1]) {
      label += ` ${splitUserName[1].charAt(0)}.`;
    }

    return label;
  }, [task]);

  return (
    <>
      {bgColor && (
        <Flex
          backgroundColor={bgColor}
          grow={1}
          height={30}
          justifyContent={"space-between"}
          padding={4}
        >
          {assignedUserName && (
            <Flex>
              <Text style={{ color: "white" }}>{assignedUserName}</Text>
            </Flex>
          )}

          <Flex>
            <Text style={{ color: "white" }}>{task.duration}</Text>
          </Flex>
        </Flex>
      )}
    </>
  );
};
