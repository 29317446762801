import {
  Alert,
  Badge,
  Button,
  Divider,
  Flex,
  Heading,
  Menu,
  MenuItem,
  Placeholder,
  TabItem,
  Tabs,
  Text,
} from "@aws-amplify/ui-react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  MdBed,
  MdCameraAlt,
  MdHouse,
  MdMoreVert,
  MdPerson,
  MdTimelapse,
} from "react-icons/md";
import { RxSize } from "react-icons/rx";
import { useParams } from "react-router-dom";
import i18n from "../../i18n/i18n";
import { theme } from "../../theme";
import ServiceUserAutocomplete from "../Properties/ServiceUserAutocomplete";
import TextMuted from "../shared/components/TextMuted";
import { useUserProfile } from "../shared/components/UserProfileProvider";
import { TaskProgressStatus } from "../shared/hooks/core-coordination-task";
import { useCurrentUser } from "../shared/hooks/useCurrentUser";
import { useSearchParamsUtils } from "../shared/hooks/useSearchParamsUtils";
import {
  deleteTask,
  useTask,
  useTaskRequirementValues,
  useTaskTemplates,
  useTaskUpdate,
  useTaskUpdateProgressStatus,
} from "../shared/hooks/useTasksApi";
import ScheduledDatePicker from "./components/ScheduledDatePicker";
import TaskStateBadge from "./components/TaskStateBadge";
import ImageTimeline from "./ImageTimeline";
import ImageUploader from "./ImageUploader";
import TaskActivity from "./TaskActivity";
import TaskChat from "./TaskChat";
import TaskConnectedReservations from "./TaskConnectedReservations";
import TaskNotes from "./TaskNotes";
import TaskPropertyAccess from "./TaskPropertyAccess";
import TaskRequirements from "./TaskRequirements";
import ScheduledTimeSelector from "./components/ScheduledTimeSelector";

export default function TaskPage({
  taskId,
  onChanged,
}: {
  onChanged: () => void;
  taskId?: string;
}) {
  // use "en" locale in admin pages
  moment.locale(i18n.language);

  const params = useParams();
  const { t } = useTranslation();
  const { isCoordinatorOrMore } = useCurrentUser();
  const { userProfile } = useUserProfile();
  const { data, loading, refetch, error } = useTask(taskId || params.taskId!);
  const {
    data: templateData,
    loading: templateDataLoading,
    load: loadTemplates,
  } = useTaskTemplates();
  const { update, loading: updating } = useTaskUpdate(taskId || params.taskId!);
  const { data: requirementsData, load: loadRequirements } =
    useTaskRequirementValues(taskId || params.taskId!);

  useEffect(() => {
    if (data?.template_id) {
      loadTemplates({ templateId: data?.template_id });
    }
  }, [data, loadTemplates]);

  useEffect(() => {
    loadRequirements();
  }, [loadRequirements]);

  const [tabIndex, setTabIndex] = useState<number>(0);

  const { setSingleSearchParam } = useSearchParamsUtils();

  const { update: updateProgressStatus } = useTaskUpdateProgressStatus(
    taskId || params.taskId!
  );

  const changeTab = (tabId: number | string) => {
    setTabIndex(tabId as number);
  };

  return (
    <Flex direction={"column"} grow={1}>
      {error && <Alert variation="error">{error.message}</Alert>}

      {loading && (
        <Flex direction={"column"}>
          <Placeholder height={50}></Placeholder>
          <Placeholder height={200}></Placeholder>
          <Placeholder height={50}></Placeholder>
          <Placeholder height={50}></Placeholder>
          <Placeholder height={50}></Placeholder>
          <Placeholder height={50}></Placeholder>
          <Placeholder height={50}></Placeholder>
        </Flex>
      )}

      {data && (
        <Flex direction="column" gap={36}>
          <Flex direction={"column"}>
            <Flex
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Flex direction={"row"} alignItems={"center"}>
                <Flex direction={"column"}>
                  <TaskStateBadge task={data}></TaskStateBadge>
                </Flex>
                <Heading level={3}>{data?.address}</Heading>
              </Flex>

              {isCoordinatorOrMore &&
                data.progress_status === TaskProgressStatus.Cancelled && (
                  <Menu
                    menuAlign="end"
                    trigger={
                      <Button variation={"link"} size="large">
                        <MdMoreVert />
                      </Button>
                    }
                  >
                    <MenuItem
                      onClick={() => {
                        deleteTask(data.task_id);

                        setSingleSearchParam("taskId", undefined);
                        onChanged();
                      }}
                    >
                      <Flex alignItems={"center"}>
                        <Flex>{t("Service.DeleteTask")}</Flex>
                      </Flex>
                    </MenuItem>
                  </Menu>
                )}
            </Flex>
            <TextMuted>ID: {data?.task_id}</TextMuted>

            <Flex>
              {data.sameDayCheckinExists && (
                <Badge>{t("Service.SameDayCheckin")}</Badge>
              )}
            </Flex>

            <Flex>
              {!data.progress_status && (
                <Button
                  disabled={updating || loading}
                  variation="primary"
                  onClick={async () => {
                    await updateProgressStatus({
                      progress_status: TaskProgressStatus.Started,
                    });
                    onChanged();
                    refetch();
                  }}
                >
                  {t("Service.StartTask")}
                </Button>
              )}

              {!data.progress_status && isCoordinatorOrMore && (
                <Button
                  disabled={updating || loading}
                  variation="link"
                  color={theme.errorColor}
                  onClick={async () => {
                    // eslint-disable-next-line no-restricted-globals
                    const confirmed = confirm(
                      "ARE YOU SURE? THIS CANNOT BE UNDONE."
                    );

                    if (confirmed) {
                      await deleteTask(data.task_id);

                      setSingleSearchParam("taskId", undefined);
                      onChanged();
                    }
                  }}
                >
                  {t("Service.DeleteTask")}
                </Button>
              )}

              {data.progress_status &&
                [
                  TaskProgressStatus.Planned,
                  TaskProgressStatus.Started,
                ].includes(data.progress_status) &&
                isCoordinatorOrMore && (
                  <Button
                    disabled={updating || loading}
                    variation="link"
                    color={theme.errorColor}
                    onClick={async () => {
                      // eslint-disable-next-line no-restricted-globals
                      const confirmed = confirm(
                        "Are you sure to cancel this task?"
                      );

                      if (confirmed) {
                        await update({
                          task_id: data.task_id,
                          progress_status: TaskProgressStatus.Cancelled,
                        });

                        setSingleSearchParam("taskId", undefined);
                        onChanged();
                      }
                    }}
                  >
                    {t("Service.CancelTask")}
                  </Button>
                )}

              {!data.progress_status ||
                (data.progress_status === TaskProgressStatus.Started && (
                  <Flex direction={"column"}>
                    <Button
                      disabled={
                        updating ||
                        loading ||
                        (requirementsData &&
                          Number(requirementsData.fullfilled) <
                            Number(requirementsData.requirementsCount))
                      }
                      variation="primary"
                      onClick={async () => {
                        await updateProgressStatus({
                          progress_status: TaskProgressStatus.Completed,
                        });
                        refetch();
                        onChanged();
                      }}
                    >
                      {t("Service.MarkAsFinished")}
                    </Button>
                  </Flex>
                ))}

              <Flex direction={"row"}>
                <Flex alignItems={"center"}>
                  {data.progress_status === TaskProgressStatus.Started && (
                    <Badge>Started</Badge>
                  )}
                  {data.progress_status === TaskProgressStatus.Completed && (
                    <Badge variation="success">Finished</Badge>
                  )}
                </Flex>

                {data.duration && (
                  <Flex alignItems={"center"}>
                    <Heading>
                      <MdTimelapse></MdTimelapse>Time
                    </Heading>

                    {data.duration}
                  </Flex>
                )}
              </Flex>
            </Flex>
          </Flex>

          {data.progress_status !== TaskProgressStatus.Cancelled &&
            requirementsData &&
            requirementsData.fullfilled <
              requirementsData?.requirementsCount && (
              <Alert>
                {t("Service.RequirementsInfo")}{" "}
                {`${requirementsData.fullfilled} / ${requirementsData?.requirementsCount}`}
              </Alert>
            )}

          {data.progress_status === TaskProgressStatus.Cancelled && (
            <Alert variation="warning" textAlign={"center"}>
              {t("Service.Cancelled")}
            </Alert>
          )}

          <Tabs
            overflow={"scroll"}
            spacing="equal"
            marginBottom={12}
            onChange={changeTab}
            currentIndex={tabIndex}
          >
            <TabItem title={t("Service.TaskOverview")}>
              <Flex direction="column">
                <Flex direction={"row"} justifyContent={"flex-start"}>
                  <Flex direction={"column"} minWidth={200}>
                    <Heading>{t("Service.TaskDueOn")}</Heading>
                    {isCoordinatorOrMore &&
                      data.progress_status !== TaskProgressStatus.Cancelled && (
                        <ScheduledDatePicker
                          onChange={async (date) => {
                            if (!date) {
                              await update({
                                task_id: taskId || params.taskId!,
                                scheduled_date: null,
                              });
                            } else {
                              await update({
                                task_id: taskId || params.taskId!,
                                scheduled_date: date,
                              });
                            }

                            refetch();
                            onChanged();
                          }}
                          value={data.scheduled_date}
                        ></ScheduledDatePicker>
                      )}

                    {(!isCoordinatorOrMore ||
                      data.progress_status ===
                        TaskProgressStatus.Cancelled) && (
                      <Text>{data.scheduled_date || "-"}</Text>
                    )}
                  </Flex>

                  <Flex>
                    <Flex direction="column" gap={0}>
                      <ServiceUserAutocomplete
                        isReadOnly={
                          !isCoordinatorOrMore ||
                          data.progress_status === TaskProgressStatus.Cancelled
                        }
                        label={t("Service.AssignedUser")}
                        defaultValue={String(data.user_name || "")}
                        onSave={async (userId) => {
                          await update({
                            task_id: taskId || params.taskId!,
                            user_id: userId,
                          });
                          refetch();
                          onChanged();
                        }}
                      ></ServiceUserAutocomplete>
                    </Flex>
                  </Flex>
                </Flex>

                <Flex direction={"column"}>
                  <Heading>{t("Service.ScheduledAt")}</Heading>

                  {isCoordinatorOrMore && (
                    <ScheduledTimeSelector
                      isDisabled={
                        data.progress_status === TaskProgressStatus.Cancelled
                      }
                      value={data.scheduled_time}
                      hideLabel={true}
                      onChange={async (value) => {
                        await update({
                          task_id: taskId || params.taskId!,
                          scheduled_time: value,
                        });

                        refetch();
                        onChanged();
                      }}
                    ></ScheduledTimeSelector>
                  )}

                  {!isCoordinatorOrMore && <Text>{data?.scheduled_time}</Text>}
                </Flex>

                <Flex direction={"column"}>
                  <Flex direction={"column"}>
                    <TaskNotes
                      isReadOnly={
                        !isCoordinatorOrMore ||
                        data.progress_status === TaskProgressStatus.Cancelled
                      }
                      taskId={data.task_id}
                      defaultValue={data.notes}
                    ></TaskNotes>
                  </Flex>

                  {data && (
                    <Flex direction={"column"} marginTop={32}>
                      <Divider></Divider>
                      <Flex direction={"column"}>
                        <Flex alignItems={"center"}>
                          <MdHouse></MdHouse>
                          <Heading>{t("Service.Listing")}</Heading>
                        </Flex>

                        <Flex gap={32}>
                          <Flex alignItems="center" gap={4}>
                            <MdPerson></MdPerson>
                            <TextMuted>
                              max. {data.listing.person_capacity}
                            </TextMuted>
                          </Flex>
                          <Flex alignItems="center" gap={4}>
                            <MdBed></MdBed>
                            <TextMuted>{data.listing.beds_number}</TextMuted>
                          </Flex>
                          <Flex alignItems="center" gap={4}>
                            <RxSize></RxSize>
                            <TextMuted>
                              {data.listing.squere_meters} m2
                            </TextMuted>
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                  )}

                  {data && (
                    <Flex direction={"column"} marginTop={32}>
                      <Divider></Divider>
                      <TaskConnectedReservations data={data} />
                    </Flex>
                  )}

                  <Flex
                    direction={"column"}
                    justifyContent={"center"}
                    marginTop={32}
                  >
                    <Divider></Divider>
                    <TaskChat taskId={data.task_id}></TaskChat>
                  </Flex>

                  <Flex direction="column" marginTop={32}>
                    <Divider></Divider>
                    <Flex alignItems={"center"}>
                      <MdCameraAlt></MdCameraAlt>
                      <Heading>Attachments</Heading>
                    </Flex>

                    <ImageUploader
                      taskId={taskId || params.taskId!}
                      onChange={() => {
                        refetch();
                      }}
                    ></ImageUploader>
                  </Flex>
                  <Flex direction={"column"} justifyContent={"center"}>
                    <ImageTimeline images={data.images}></ImageTimeline>
                  </Flex>
                </Flex>
              </Flex>
            </TabItem>
            <TabItem title={t("Service.TaskPropertyAccess")}>
              <Flex direction={"column"} justifyContent={"center"}>
                <TaskPropertyAccess data={data}></TaskPropertyAccess>
              </Flex>
            </TabItem>

            <TabItem title={t("Service.TaskRequirements")}>
              {!templateData && !templateDataLoading && (
                <Flex direction="column" justifyContent={"center"} padding={42}>
                  <TextMuted>
                    There are no specific requirements for this task.
                  </TextMuted>
                </Flex>
              )}

              {templateDataLoading && (
                <Flex direction={"column"}>
                  <Placeholder height={50}></Placeholder>
                  <Placeholder height={50}></Placeholder>
                  <Placeholder height={50}></Placeholder>
                  <Placeholder height={50}></Placeholder>
                  <Placeholder height={50}></Placeholder>
                  <Placeholder height={50}></Placeholder>
                </Flex>
              )}
              {templateData && !templateDataLoading && (
                <Flex direction={"column"}>
                  <TaskRequirements
                    readonly={data.user_id !== userProfile?.id}
                    data={requirementsData}
                    taskId={taskId || params.taskId!}
                    templateData={templateData}
                    onChange={() => {
                      loadRequirements();
                    }}
                  ></TaskRequirements>
                </Flex>
              )}
            </TabItem>

            <TabItem title={t("Service.TaskActivity")}>
              <Flex direction={"column"} justifyContent={"center"}>
                <TaskActivity taskId={data.task_id}></TaskActivity>
              </Flex>
            </TabItem>
          </Tabs>
        </Flex>
      )}
    </Flex>
  );
}
