import { Flex } from "@aws-amplify/ui-react";
import { theme } from "../../../theme";
import { Link, useLocation } from "react-router-dom";
import { useMemo } from "react";

export default ({
  label,
  icon,
  href,
}: {
  href: string;
  label: string;
  icon: React.ReactElement;
}) => {
  const location = useLocation();

  const active = useMemo(() => {
    return location.pathname === href;
  }, [href, location]);

  return (
    <Flex alignItems={"center"}>
      <Link
        to={href}
        style={{
          textDecoration: "none",
          fontWeight: active ? 700 : undefined,
          color: active
            ? theme.primaryColor
            : "var(--amplify-components-link-color)",
        }}
      >
        <Flex alignItems={"center"} gap={8}>
          <Flex>{icon}</Flex>
          <Flex>{label}</Flex>
        </Flex>
      </Link>
    </Flex>
  );
};
