import { Badge, Card, Flex, Heading, Text } from "@aws-amplify/ui-react";
import { useMemo } from "react";
import { theme } from "../../../theme";
import {
  CoordinationTask,
  CoordinationTaskType,
  CoreCoordinationDraftTask,
  TaskProgressStatus,
} from "../../shared/hooks/core-coordination-task";
import TaskStateBadge from "./TaskStateBadge";
import TextMuted from "../../shared/components/TextMuted";
import { MdOutlineCancel } from "react-icons/md";
import TaskCardStatusFooter from "./TaskCardStatusFooter";
import { getColorForTask } from "./getStyleForTaskCard";
import { useTranslation } from "react-i18next";

export default ({
  onClick,
  task,
}: {
  task: CoordinationTask;
  onClick: () => void;
}) => {
  const { t } = useTranslation();
  const isCancelled = useMemo(() => {
    return task.progress_status === TaskProgressStatus.Cancelled;
  }, [task]);

  const borderColor = useMemo(() => {
    return getColorForTask({ task });
  }, [task]);

  return (
    <Card
      border={
        isCancelled
          ? "dashed red"
          : (task as CoreCoordinationDraftTask).is_system_proposed &&
            !isCancelled
          ? "dashed lightgrey"
          : borderColor
          ? `solid ${borderColor}`
          : undefined
      }
      variation="elevated"
      backgroundColor={isCancelled ? theme.lines : undefined}
      onClick={onClick}
      padding={0}
    >
      <Flex gap={12} direction={"column"} padding={12}>
        <Flex justifyContent={"space-between"}>
          <Flex direction={"column"} gap={0}>
            <Heading>{t(`Service.${task.type}` as any)}</Heading>
            <Text variation="secondary">{task.address}</Text>
          </Flex>
          <Flex direction={"column"}>
            <TaskStateBadge task={task}></TaskStateBadge>
          </Flex>
        </Flex>

        <Flex>
          {task.notes && <TextMuted>{task.notes}</TextMuted>}

          {(task as CoreCoordinationDraftTask).sameDayCheckinExists &&
            task.type !== CoordinationTaskType.Delivery && (
              <Badge variation="warning">Same Day Checkin</Badge>
            )}
        </Flex>

        {isCancelled && (
          <Flex alignItems={"center"} gap={4}>
            <MdOutlineCancel color={theme.errorColor}></MdOutlineCancel>
            <TextMuted>{t("Service.Cancelled")}</TextMuted>
          </Flex>
        )}
        {(task as CoreCoordinationDraftTask).is_system_proposed &&
          !isCancelled && (
            <Flex>
              <TextMuted>{t("Service.Draft")}</TextMuted>
            </Flex>
          )}
      </Flex>

      <TaskCardStatusFooter task={task}></TaskCardStatusFooter>
    </Card>
  );
};
