import { Button, Flex, Heading, Menu, MenuItem } from "@aws-amplify/ui-react";
import moment from "moment";
import { useState } from "react";
import PropertyAutocomplete from "../Properties/PropertyAutocomplete";
import ServiceUserAutocomplete from "../Properties/ServiceUserAutocomplete";
import TaskTypeAutocomplete from "../Properties/TaskTypeAutocomplete";
import Modal from "../shared/components/Modal";
import {
  CoordinationTaskType,
  CoreCoordinationDraftTask,
  TaskProgressStatus,
} from "../shared/hooks/core-coordination-task";
import { useTaskCreate } from "../shared/hooks/useTasksApi";
import ScheduledDatePicker from "./components/ScheduledDatePicker";
import ScheduledTimeSelector from "./components/ScheduledTimeSelector";
import TaskNotesComponent from "./TaskNotesComponent";
import { useTranslation } from "react-i18next";
import { MdMoreVert } from "react-icons/md";

export default ({
  onClose,
  onCreated,
  draft,
}: {
  draft?: CoreCoordinationDraftTask;
  onCreated: (taskId: string) => void;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const [task, setTask] = useState<
    | {
        taskId?: string;
        type?: CoordinationTaskType;
        propertyId?: number;
        userId?: number;
        notes?: string;
        scheduledDate?: string;
        scheduledTime?: string | null;
      }
    | undefined
  >({
    propertyId: draft?.property_id,
    type: draft?.type,
    taskId: draft?.task_id,
    scheduledTime: draft?.scheduled_time,
    scheduledDate: draft?.scheduled_date,
    notes: draft?.notes,
  });
  const { create, loading } = useTaskCreate();

  return (
    <>
      <Modal title={t("Service.AddTask")} onClose={onClose}>
        <Flex direction={"column"} gap={16}>
          <Flex direction={"column"} gap={0}>
            <PropertyAutocomplete
              required={true}
              defaultValue={draft?.address}
              onSelected={async (propertyId) => {
                setTask({ ...task, propertyId });
              }}
            ></PropertyAutocomplete>
          </Flex>

          <Flex direction={"column"} gap={0}>
            <TaskTypeAutocomplete
              defaultValue={
                draft ? t(`Service.${draft?.type}` as any) : undefined
              }
              onSelected={async (type) => {
                setTask({ ...task, type });
              }}
            ></TaskTypeAutocomplete>
          </Flex>

          <Flex direction={"column"}>
            <TaskNotesComponent
              required={!draft?.task_id}
              onChange={async (notes) => {
                setTask({ ...task, notes });
              }}
              updating={false}
              defaultValue={draft?.notes}
            ></TaskNotesComponent>
          </Flex>

          <Flex direction={"column"} gap={0}>
            <ServiceUserAutocomplete
              label={t("Service.AssignedUser")}
              onSave={async (userId) => {
                setTask({ ...task, userId });
              }}
            ></ServiceUserAutocomplete>
          </Flex>

          <Flex direction={"column"}>
            <Heading>{t("Service.PlanSchedule")}</Heading>
            <Flex>
              <Flex>
                <ScheduledDatePicker
                  onChange={(date) => {
                    if (!date) {
                      return setTask({
                        ...task,
                        scheduledDate: undefined,
                      });
                    }

                    setTask({
                      ...task,
                      scheduledDate: moment(date?.toString()).format(
                        "YYYY-MM-DD"
                      ),
                    });
                  }}
                  value={task?.scheduledDate}
                ></ScheduledDatePicker>
              </Flex>
              <ScheduledTimeSelector
                value={task?.scheduledTime}
                onChange={(value) => {
                  setTask({ ...task, scheduledTime: value });
                }}
              ></ScheduledTimeSelector>
            </Flex>
          </Flex>
        </Flex>

        <Flex marginTop={32}>
          <Button
            onClick={async () => {
              const newTask = await create({
                task_id: task?.taskId,
                type: task?.type,
                property_id: task?.propertyId,
                user_id: task?.userId,
                scheduled_date: task?.scheduledDate,
                scheduled_time: task?.scheduledTime,
                notes: task?.notes,
              });

              if (newTask) {
                onCreated(newTask.task_id!);
                onClose();
              }
            }}
            isDisabled={loading}
            variation="primary"
            grow={1}
            disabled={
              !task || !task.propertyId || (!task.notes && !task?.taskId)
            }
          >
            {t("Common.Save")}
          </Button>

          <Flex>
            <Menu
              menuAlign="end"
              trigger={
                <Button variation={"link"} size="large">
                  <MdMoreVert />
                </Button>
              }
            >
              <MenuItem
                onClick={async () => {
                  const cancelledTask = await create({
                    task_id: task?.taskId,
                    property_id: task?.propertyId,
                    type: task?.type,
                    progress_status: TaskProgressStatus.Cancelled,
                    scheduled_date: task?.scheduledDate,
                    notes: task?.notes,
                  });

                  if (cancelledTask?.task_id) {
                    onCreated(cancelledTask.task_id);
                  }
                }}
              >
                <Flex alignItems={"center"}>
                  <Flex>{t("Service.CancelTask")}</Flex>
                </Flex>
              </MenuItem>
            </Menu>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};
