import { API } from "../../utils/API";
import { ReportTable } from "../common";
import { useApi } from "../useApi";

export const addUserToServiceGroup = async (
  userId: number
): Promise<undefined> =>
  await API.put(`/v1/service/people`, {
    body: { userId },
  });

export const useServicePeople = () =>
  useApi<ReportTable<{ email: string }, undefined>, undefined>(
    `/v1/service/people`
  );

export const deleteFromServiceTeam = (userId: number) =>
  API.post(`/v1/service/people/groups/remove`, {
    body: {
      userId,
    },
  });
