import {
  Alert,
  Button,
  FieldGroupIcon,
  Flex,
  Heading,
  SwitchField,
  TabItem,
  Tabs,
  Text,
  TextField,
} from "@aws-amplify/ui-react";
import {
  ChangeEvent,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Accordion, Breadcrumb, BreadcrumbItem } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  MdCancel,
  MdCheck,
  MdEdit,
  MdInfo,
  MdRefresh,
  MdSettings,
} from "react-icons/md";
import { useSearchParams } from "react-router-dom";
import { logger } from "../../logger";
import DocsSection from "../Docs/DocsSection";
import FieldValues from "../Search/FieldValues";
import BadgeField from "../shared/components/BadgeField";
import ExternalLink from "../shared/components/ExternalLink";
import ExternalResourceLink from "../shared/components/ExternalResourceLink";
import Headline2 from "../shared/components/Headline2";
import Loader from "../shared/components/Loader";
import { syncHostwayListing } from "../shared/hooks/useAdminPmsHostawayApi";
import {
  PropertyDetails,
  updatePropertyPmsId,
  useProperties,
} from "../shared/hooks/useProperties";
import HostawayIdAutocomplete from "./HostawayIdAutocomplete";
import HostawayPropertyJson from "./HostawayProperty";
import PropertyAssignedUser from "./PropertyAssignedUser";
import PropertyDetailsImages from "./PropertyDetailsImages";
import StatusIndicator from "./StatusIndicator";
import PropertyTasksReportPage from "../CleaningSchedule/PropertyTasksReportPage";
import { useCurrentUser } from "../shared/hooks/useCurrentUser";

export default () => {
  const [propertyDetails, setPropertyDetails] =
    useState<PropertyDetails<boolean>>();
  const [error, setError] = useState<Error>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [changing, setChanging] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isAdmin } = useCurrentUser();
  const investObjectId = useMemo(() => {
    return searchParams.get("id");
  }, [searchParams]);

  const tabIndex = useMemo(() => {
    const raw = searchParams.get("tab");
    return raw ? parseInt(raw, 10) : 0;
  }, [searchParams]);

  const { updateProperty, getProperty } = useProperties();

  const loadData = useCallback(async () => {
    if (!investObjectId) {
      setError(new Error("Something went wrong"));
      logger.error("Something went wrong");
      return;
    }

    try {
      const property = await getProperty(investObjectId);

      if (property) {
        setPropertyDetails(property);
      }
    } catch (error) {
      logger.error(error);
      setError(error as Error);
    }
  }, [investObjectId, getProperty]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleChange =
    <T extends "text" | "switch">(
      key: keyof PropertyDetails<boolean>,
      type: T
    ) =>
    (e: ChangeEvent<HTMLInputElement>): void => {
      if (propertyDetails) {
        setChanging(true);
        setSuccess(false);
        setError(undefined);

        if (type === "switch") {
          setPropertyDetails({ ...propertyDetails, [key]: e.target.checked });
        } else {
          setPropertyDetails({ ...propertyDetails, [key]: e.target.value });
        }
      }
    };

  const handleSubmit = async () => {
    setSubmitting(true);
    if (propertyDetails) {
      try {
        await updateProperty(propertyDetails.internal_id, propertyDetails);

        setSubmitting(false);
        setChanging(false);
        setSuccess(true);

        loadData();
      } catch (err) {
        setError(err as Error);
        setSubmitting(false);
      }
    }
  };

  const changeTab = (tabId: string | number) => {
    setSearchParams((params) => {
      params.set("tab", String(tabId));
      return params;
    });
  };

  const getIcon = (readOnly = false) => (
    <FieldGroupIcon>{readOnly ? <MdInfo /> : <MdEdit />}</FieldGroupIcon>
  );

  return (
    <Flex direction={"column"} grow={1}>
      <Flex alignItems={"center"}>
        <Breadcrumb>
          <BreadcrumbItem linkAs={"b"}>Properties</BreadcrumbItem>
          <BreadcrumbItem>
            {!propertyDetails && <Loader></Loader>}
            {propertyDetails &&
              `${propertyDetails?.name} (${propertyDetails.internal_id})`}
          </BreadcrumbItem>
        </Breadcrumb>
      </Flex>

      {propertyDetails && (
        <Flex alignItems={"center"}>
          <ExternalLink
            label={"Hostaway Listing"}
            href={`https://dashboard.hostaway.com/listing/${propertyDetails.hostaway_id}`}
          />

          <ExternalLink
            label={"Edit Listing"}
            href={`https://dashboard.hostaway.com/listing/${propertyDetails.hostaway_id}/edit`}
          />

          <ExternalLink label={"Airbnb"} href={propertyDetails.airbnb_url} />

          <ExternalLink
            label={"BookingCom"}
            href={propertyDetails.booking_url}
          />

          <ExternalLink label={"VRBO"} href={propertyDetails.vrbo_url} />

          <ExternalLink
            label={"GoogleTravel"}
            href={propertyDetails.google_url}
          />
        </Flex>
      )}

      {propertyDetails && !propertyDetails.hostaway_id && (
        <Flex alignItems={"center"}>
          <Alert variation="warning" grow={1}>
            This property is not assigned to PMS (Hostaway)
          </Alert>
        </Flex>
      )}

      <Tabs
        spacing="equal"
        marginBottom={12}
        onChange={changeTab}
        currentIndex={tabIndex}
      >
        <TabItem title={"Activity"}>
          {tabIndex === 0 && (
            <PropertyTasksReportPage
              propertyId={Number(investObjectId)}
            ></PropertyTasksReportPage>
          )}
        </TabItem>

        <TabItem title={"Photos"}>
          {tabIndex === 1 && (
            <PropertyDetailsImages
              propertyId={Number(investObjectId)}
            ></PropertyDetailsImages>
          )}
        </TabItem>

        <TabItem title={"Access"}>
          <Heading variation={"primary"}>Access information</Heading>

          {propertyDetails && (
            <FieldValues
              displayConfig={{
                checkin_instructions: "textarea",
                checkin_instructions_pl: "textarea",
                past_checkin_instructions: "textarea",
                past_checkin_instructions_pl: "textarea",
                parking_info: "textarea",
                parking_info_pl: "textarea",
              }}
              values={{
                door_code: propertyDetails.door_code,
                checkin_instructions: propertyDetails.checkin_instructions,
                checkin_instructions_pl:
                  propertyDetails.checkin_instructions_pl,
                past_checkin_instructions:
                  propertyDetails.past_checkin_instructions,
                past_checkin_instructions_pl:
                  propertyDetails.past_checkin_instructions_pl,
                parking_info: propertyDetails.parking_info,
                parking_info_pl: propertyDetails.parking_info_pl,
              }}
            ></FieldValues>
          )}
        </TabItem>

        <TabItem
          isDisabled={!isAdmin}
          title={
            <Flex alignItems={"center"} justifyContent={"center"}>
              <MdSettings></MdSettings>
              Settings
            </Flex>
          }
        >
          {propertyDetails && (
            <Flex direction={"column"}>
              <TextField
                readOnly
                disabled={true}
                label="Property ID"
                value={propertyDetails.internal_id}
                innerStartComponent={getIcon(true)}
              />

              <TextField
                label="Name"
                value={propertyDetails.name}
                onChange={handleChange("name", "text")}
                innerStartComponent={getIcon()}
              />

              <SwitchField
                label="Enable for Management Contracts"
                labelPosition="end"
                defaultChecked={propertyDetails.is_mgmt_property}
                onChange={handleChange("is_mgmt_property", "switch")}
              ></SwitchField>

              <SwitchField
                label="Enable for Invest Contracts"
                labelPosition="end"
                defaultChecked={propertyDetails.is_invest_property}
                onChange={handleChange("is_invest_property", "switch")}
              ></SwitchField>

              <SwitchField
                label="Disable Property"
                descriptiveText="If disabled, no payouts will be generated."
                labelPosition="end"
                defaultChecked={propertyDetails.disabled}
                onChange={handleChange("disabled", "switch")}
                isDisabled={!propertyDetails.hostaway_id}
              ></SwitchField>

              {!propertyDetails.hostaway_id ? (
                <Alert
                  marginTop={5}
                  variation="error"
                  isDismissible={true}
                  hasIcon={true}
                  heading="This property can't be enabled because it's not connected to PMS yet!"
                />
              ) : propertyDetails.disabled ? (
                <Alert
                  marginTop={5}
                  variation="error"
                  isDismissible={true}
                  hasIcon={true}
                  heading="Property is disabled!"
                />
              ) : (
                <Fragment>
                  <Flex grow={1} alignItems={"center"}>
                    <TextField
                      grow={1}
                      label="Airbnb URL"
                      value={propertyDetails.airbnb_url}
                      innerStartComponent={getIcon(true)}
                      readOnly
                    />
                    <ExternalResourceLink href={propertyDetails.airbnb_url} />
                  </Flex>

                  <Flex grow={1} alignItems={"center"}>
                    <TextField
                      grow={1}
                      label="Booking URL"
                      value={propertyDetails.booking_url}
                      readOnly={false}
                      onChange={handleChange("booking_url", "text")}
                      innerStartComponent={getIcon()}
                    />
                    <ExternalResourceLink href={propertyDetails.booking_url} />
                  </Flex>

                  <Flex grow={1} alignItems={"center"}>
                    <TextField
                      grow={1}
                      label="Google URL"
                      value={propertyDetails.google_url}
                      innerStartComponent={getIcon(true)}
                      readOnly
                    />
                    <ExternalResourceLink href={propertyDetails.google_url} />
                  </Flex>

                  <Flex grow={1} alignItems={"center"}>
                    <TextField
                      grow={1}
                      label="vrbo URL"
                      value={propertyDetails.vrbo_url}
                      innerStartComponent={getIcon(true)}
                      readOnly
                    />
                    <ExternalResourceLink href={propertyDetails.vrbo_url} />
                  </Flex>
                </Fragment>
              )}

              {error && (
                <Alert
                  marginTop={5}
                  variation="error"
                  isDismissible={true}
                  onDismiss={() => setError(undefined)}
                  hasIcon={true}
                  heading="Some error happended!"
                >
                  {error.message}
                </Alert>
              )}

              {success && (
                <Alert
                  marginTop={5}
                  variation="success"
                  isDismissible={true}
                  onDismiss={() => setSuccess(false)}
                  hasIcon={true}
                  heading="Data saved successfully!"
                />
              )}

              <Flex direction={"row"}>
                <Button
                  variation="primary"
                  gap={"0.5rem"}
                  onClick={handleSubmit}
                  disabled={!changing || submitting}
                  isLoading={submitting}
                  loadingText={t("Confirm")}
                >
                  <MdCheck /> Save
                </Button>
                <Button gap={"0.5rem"} onClick={() => window.location.reload()}>
                  <MdCancel /> Reset
                </Button>
              </Flex>
            </Flex>
          )}
        </TabItem>

        <TabItem title={"Contracts"} isDisabled={!isAdmin}>
          {propertyDetails && (
            <Flex direction={"column"}>
              <Headline2>Assigned Contracts</Headline2>

              <BadgeField
                label={"Management Contracts"}
                badges={propertyDetails.assigned_mgmt_contracts?.map((id) => ({
                  displayValue: `mgmt${id}`,
                  link: `/contract/details/mgmt/${id}`,
                }))}
              />

              <BadgeField
                label={"Invest Contracts"}
                badges={propertyDetails.assigned_invest_contracts?.map(
                  (id) => ({
                    displayValue: `invest${id}`,
                    link: `/contract/details/invest/${id}`,
                  })
                )}
              />

              {propertyDetails.users && (
                <Flex direction={"column"}>
                  <Headline2>Assigned Users</Headline2>
                  <Flex grow={1} direction={"column"}>
                    {propertyDetails.users?.map((user) => (
                      <PropertyAssignedUser
                        key={user.id}
                        user={user}
                      ></PropertyAssignedUser>
                    ))}
                  </Flex>
                </Flex>
              )}

              <DocsSection docs={propertyDetails.docs || []}></DocsSection>
            </Flex>
          )}
        </TabItem>

        <TabItem
          isDisabled={!isAdmin}
          key={1}
          title={
            <Flex alignItems={"center"} gap={4}>
              {propertyDetails && (
                <StatusIndicator
                  ok={!!propertyDetails?.hostaway_id}
                ></StatusIndicator>
              )}
              PMS (Hostaway)
            </Flex>
          }
        >
          <Flex marginTop={12} gap={36} direction={"column"}>
            <Flex direction={"column"}>
              <Heading variation={"primary"}>Connect to Hostaway</Heading>
              <Text variation="info">
                Here you can connect the RENTUJEMY property to a PMS system. It
                will start synchronizing reservations calendar with the owner
                portal.
              </Text>

              {propertyDetails && (
                <Flex basis={"50%"}>
                  <HostawayIdAutocomplete
                    onSave={async (newHostawayId) => {
                      if (!newHostawayId) {
                        throw new Error("Cannot set to undefined value");
                      }

                      await updatePropertyPmsId(propertyDetails.internal_id, {
                        hostaway_id: newHostawayId,
                      });
                    }}
                    defaultValue={propertyDetails.hostaway_id}
                  ></HostawayIdAutocomplete>
                </Flex>
              )}
            </Flex>

            {propertyDetails && (
              <Flex direction={"column"}>
                <Flex justifyContent="space-between">
                  <Heading variation={"primary"}>PMS data</Heading>
                  <Button
                    onClick={async () => {
                      await syncHostwayListing(propertyDetails.hostaway_id);

                      alert("Listing synchronized.");
                    }}
                  >
                    <MdRefresh></MdRefresh>
                  </Button>
                </Flex>
                <Accordion defaultActiveKey="0" alwaysOpen={true}>
                  <Accordion.Item eventKey={"1"}>
                    <Accordion.Header>
                      Hostway Listing API Data
                    </Accordion.Header>
                    <Accordion.Body>
                      {propertyDetails && (
                        <HostawayPropertyJson
                          hostawayListingId={String(
                            propertyDetails.hostaway_id
                          )}
                        ></HostawayPropertyJson>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Flex>
            )}
          </Flex>
        </TabItem>
      </Tabs>
    </Flex>
  );
};
