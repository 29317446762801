import { Flex, TextField } from "@aws-amplify/ui-react";
import { useState } from "react";
import { useUpdatePricing } from "./usePricing";

export default ({
  propertyId,
  initialValue,
  name,
}: {
  name: string;
  propertyId: number;
  initialValue: number;
}) => {
  const { update } = useUpdatePricing();
  const [value, setValue] = useState(initialValue);

  return (
    <Flex direction="column">
      <TextField
        label={""}
        value={value}
        min={99}
        type={"text"}
        onChange={async (event) => {
          const newValue = Number(event.target.value);

          if (newValue) {
            setValue(newValue);
            await update({
              property_id: propertyId,
              [name]: newValue,
            });
          }
        }}
        max={9999}
      />
    </Flex>
  );
};
