import { Flex, TextAreaField, TextField } from "@aws-amplify/ui-react";

export default ({
  values,
  displayConfig,
}: {
  displayConfig?: Record<string, "input" | "textarea">;
  values: Record<string, string>;
}) => {
  return (
    <Flex direction={"column"} grow={1}>
      {Object.keys(values).map((key) => (
        <Flex key={`field-wrapper-${key}`} grow={1}>
          {displayConfig && displayConfig[key] === "textarea" && (
            <TextAreaField
              key={`field-${key}`}
              readOnly
              disabled
              rows={10}
              label={key}
              value={
                typeof values[key] === "object"
                  ? JSON.stringify(values[key])
                  : values[key]
              }
            />
          )}

          {displayConfig && displayConfig[key] === "input" && (
            <TextField
              key={`field-${key}`}
              readOnly
              disabled
              label={key}
              value={
                typeof values[key] === "object"
                  ? JSON.stringify(values[key])
                  : values[key]
              }
            />
          )}

          {(!displayConfig || !displayConfig[key]) && (
            <TextField
              key={`field-${key}`}
              readOnly
              disabled
              label={key}
              value={
                typeof values[key] === "object"
                  ? JSON.stringify(values[key])
                  : values[key]
              }
            />
          )}
        </Flex>
      ))}
    </Flex>
  );
};
