import { Flex, Link, useBreakpointValue } from "@aws-amplify/ui-react";
import { PropsWithChildren, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  MdAnalytics,
  MdCleaningServices,
  MdCloud,
  MdDocumentScanner,
  MdHouse,
  MdPages,
  MdPayments,
  MdPerson,
  MdReport,
  MdSearch,
  MdSettings,
  MdWork,
} from "react-icons/md";
import { useLocation } from "react-router-dom";
import { theme } from "../theme";
import SideMenuCoordinator from "./CleaningSchedule/SideMenuCoordinator";
import SideMenuService from "./CleaningSchedule/SideMenuService";
import SideMenuLink from "./shared/components/SideMenuLink";
import { useCurrentUser } from "./shared/hooks/useCurrentUser";
import Header from "./shared/molecules/Header/Header";

enum DomainModules {
  Service = 0,
  Reporting = 1,
  Payouts = 2,
  Management = 3,
}

export default ({ children }: PropsWithChildren) => {
  const { t } = useTranslation();
  const sidebarWidth = 250;
  const domainSidebarWith = 50;

  const isMatchingMobile = useBreakpointValue({ base: true, medium: false });
  const { isAdmin, isAccounting, isService, isCoordinator } = useCurrentUser();
  const location = useLocation();

  const [tabIndex, setTabIndex] = useState<number | undefined>();

  useEffect(() => {
    if (location.pathname.startsWith("/reporting")) {
      setTabIndex(DomainModules.Reporting);
    }

    if (location.pathname === "/") {
      if (isAccounting) {
        setTabIndex(DomainModules.Reporting);
      } else {
        setTabIndex(undefined);
      }
    }

    if (location.pathname.startsWith("/service")) {
      setTabIndex(DomainModules.Service);
    }

    if (location.pathname.startsWith("/payouts")) {
      setTabIndex(DomainModules.Payouts);
    }

    if (
      location.pathname.startsWith("/properties") ||
      location.pathname.startsWith("/users") ||
      location.pathname.startsWith("/contracts") ||
      location.pathname.startsWith("/documents") ||
      location.pathname.startsWith("/occupancy")
    ) {
      setTabIndex(DomainModules.Management);
    }
  }, [location, isAccounting]);

  return (
    <Flex direction={"column"} height={"100vh"}>
      <Flex>
        {!isMatchingMobile && (
          <Flex
            className="noprint"
            position={"fixed"}
            height={"100vh"}
            width={
              typeof tabIndex !== "undefined" ? sidebarWidth : domainSidebarWith
            }
            backgroundColor={theme.background}
            direction={"row"}
            gap={0}
            justifyContent={"center"}
          >
            <Flex
              height={"100%"}
              backgroundColor={theme.accentColor}
              width={domainSidebarWith}
              overflow={"scroll"}
              alignItems={"center"}
              justifyContent={"space-evenly"}
              direction="column"
            >
              {(isAdmin || isCoordinator || isService) && (
                <Flex
                  width={domainSidebarWith}
                  grow={1}
                  justifyContent={"center"}
                  alignItems={"center"}
                  backgroundColor={
                    tabIndex === DomainModules.Service
                      ? theme.primaryColor
                      : undefined
                  }
                  onClick={() => {
                    setTabIndex(DomainModules.Service);
                  }}
                >
                  <MdCleaningServices
                    color={
                      tabIndex === DomainModules.Service
                        ? "white"
                        : theme.primaryColor
                    }
                  ></MdCleaningServices>
                </Flex>
              )}
              {(isAdmin || isAccounting) && (
                <Flex
                  alignItems={"center"}
                  grow={1}
                  justifyContent={"center"}
                  width={domainSidebarWith}
                  backgroundColor={
                    tabIndex === DomainModules.Reporting
                      ? theme.primaryColor
                      : undefined
                  }
                  onClick={() => {
                    setTabIndex(DomainModules.Reporting);
                  }}
                >
                  <MdAnalytics
                    color={
                      tabIndex === DomainModules.Reporting
                        ? "white"
                        : theme.primaryColor
                    }
                  ></MdAnalytics>
                </Flex>
              )}

              {isAdmin && (
                <Flex
                  alignItems={"center"}
                  grow={1}
                  width={domainSidebarWith}
                  justifyContent={"center"}
                  backgroundColor={
                    tabIndex === DomainModules.Payouts
                      ? theme.primaryColor
                      : undefined
                  }
                  onClick={() => {
                    setTabIndex(DomainModules.Payouts);
                  }}
                >
                  <MdPayments
                    color={
                      tabIndex === DomainModules.Payouts
                        ? "white"
                        : theme.primaryColor
                    }
                  ></MdPayments>
                </Flex>
              )}

              {isAdmin && (
                <Flex
                  backgroundColor={
                    tabIndex === DomainModules.Management
                      ? theme.primaryColor
                      : undefined
                  }
                  justifyContent={"center"}
                  width={domainSidebarWith}
                  alignItems={"center"}
                  grow={1}
                  onClick={() => {
                    setTabIndex(DomainModules.Management);
                  }}
                >
                  <MdSettings
                    color={
                      tabIndex === DomainModules.Management
                        ? "white"
                        : theme.primaryColor
                    }
                  ></MdSettings>
                </Flex>
              )}
            </Flex>

            {typeof tabIndex !== "undefined" && (
              <Flex
                className="noprint"
                direction="column"
                overflow={"scroll"}
                grow={1}
                padding={16}
                paddingBottom={32}
              >
                {(isAdmin || isAccounting) && (
                  <SideMenuLink
                    label={"Search"}
                    icon={<MdSearch />}
                    href="/"
                  ></SideMenuLink>
                )}

                {tabIndex === 0 && (
                  <>
                    {(isCoordinator || isAdmin) && <SideMenuCoordinator />}

                    {(isCoordinator || isService || isAdmin) && (
                      <SideMenuService />
                    )}
                  </>
                )}

                {tabIndex === 1 && (
                  <>
                    <Flex>Accounting</Flex>

                    <SideMenuLink
                      label={"Consolidated Report"}
                      icon={<MdReport />}
                      href="/reporting/consolidated"
                    ></SideMenuLink>

                    <SideMenuLink
                      label={"Reservations Report"}
                      icon={<MdReport />}
                      href="/reporting"
                    ></SideMenuLink>

                    <SideMenuLink
                      label={"Charges Report"}
                      icon={<MdReport />}
                      href="/reporting/charges"
                    ></SideMenuLink>
                  </>
                )}

                {tabIndex === 3 && isAdmin && (
                  <>
                    <Flex>Management</Flex>
                    <SideMenuLink
                      label={t("Service.Properties")}
                      icon={<MdHouse />}
                      href="/properties"
                    ></SideMenuLink>

                    <SideMenuLink
                      label={"Pricing"}
                      icon={<MdPerson />}
                      href="/pricing"
                    ></SideMenuLink>

                    <SideMenuLink
                      label={"Users"}
                      icon={<MdPerson />}
                      href="/users"
                    ></SideMenuLink>

                    <SideMenuLink
                      label={"Access Instructions"}
                      icon={<MdHouse />}
                      href="/access-instructions"
                    ></SideMenuLink>

                    <SideMenuLink
                      label={"Contracts"}
                      icon={<MdDocumentScanner />}
                      href="/contracts"
                    ></SideMenuLink>

                    <SideMenuLink
                      label={"Documents"}
                      icon={<MdPages />}
                      href="/documents"
                    ></SideMenuLink>

                    <SideMenuLink
                      label={"Occupancy Report"}
                      icon={<MdPages />}
                      href="/occupancy"
                    ></SideMenuLink>
                  </>
                )}

                {tabIndex === 2 && (
                  <>
                    <Flex>Payouts</Flex>
                    <SideMenuLink
                      label={"Validation"}
                      icon={<MdReport />}
                      href="/payouts/validation"
                    ></SideMenuLink>

                    <SideMenuLink
                      label={"Planning"}
                      icon={<MdWork />}
                      href="/payouts/planning"
                    ></SideMenuLink>

                    <SideMenuLink
                      label={"Execution"}
                      icon={<MdPayments />}
                      href="/payouts/execution"
                    ></SideMenuLink>

                    <Flex>Debug</Flex>
                    <Flex>
                      <Link href="/background-jobs">
                        <MdCloud></MdCloud> Background Jobs
                      </Link>
                    </Flex>
                  </>
                )}
              </Flex>
            )}
          </Flex>
        )}
        <Flex
          className="print-layout-navcolumn"
          marginLeft={isMatchingMobile ? undefined : sidebarWidth}
          grow={1}
          overflow={"hidden"}
          direction={"column"}
          padding={16}
        >
          <Flex grow={1}>
            <Header />
          </Flex>

          <Flex overflow={"scroll"}>{children}</Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
