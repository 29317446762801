import { CoreReservation } from "../types/core-reservation";
import { API } from "../utils/API";

export interface OccupancyReport {
  summary: {
    days: number;
    unrentedCount: number;
  };
  listingsWarnings: {
    name: string;
    airbnbName: string;
    editListing: string;
    editPrice: string;
    airbnbUrl: string;
    recommendations: string[];
  }[];
}

export interface Invoice {
  invoice_external_id: string;
  invoice_number: string;
  reservation_id: string;
}

export const getReservation = async (id: string): Promise<CoreReservation> =>
  (await API.get(`/v1/admin/coordination/reservations/${id}`)).reservation;

export const updateReservation = async (
  id: string,
  data: CoreReservation
): Promise<CoreReservation> => {
  const payload: Record<string, String> = {};

  Object.keys(data).forEach((key) => {
    const objKey = key as keyof CoreReservation;

    payload[objKey] = String(data[objKey]);
  });

  return (
    await API.put(`/v1/admin/reporting/reservation/${id}`, {
      body: payload,
    })
  ).reservation;
};

export const getInvoice = async (invoiceNumber: string): Promise<Invoice> =>
  (await API.get(`/v1/admin/coordination/invoices?invoiceNo=${invoiceNumber}`))
    .invoice;

export const getOccupancyReport = async (
  days: number
): Promise<OccupancyReport> =>
  await API.get(`/v1/admin/occupancy/unbooked-listings?days=${days}`);
