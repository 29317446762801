import { Button, Flex, Heading, TextField } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import useForm from "../shared/hooks/useForm";
import {
  CleaningSizeCategory,
  useTaskCleaningRates,
} from "./hooks/useTaskCleaningRates";
import Loader from "../shared/components/Loader";
import TextMuted from "../shared/components/TextMuted";

type TaskCleaningRatesUpdateFormValues = {
  studio: number;
  apartment: number;
  suite: number;
  bigSuite: number;
};

export default ({
  onSaved,
  userId,
  handleUpdate,
}: {
  userId: number;
  onSaved: () => void;
  handleUpdate: (values: TaskCleaningRatesUpdateFormValues) => Promise<void>;
}) => {
  const { setDirty, valid, dirty, formValues, setFormValues, setFieldValue } =
    useForm<TaskCleaningRatesUpdateFormValues>({
      studio: 0,
      apartment: 0,
      suite: 0,
      bigSuite: 0,
    });
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { load, data, loading } = useTaskCleaningRates();

  useEffect(() => {
    load({ userId });
  }, [userId, load]);

  useEffect(() => {
    if (data) {
      setFormValues({
        studio:
          data.rates.find(
            (rate) => rate.size_category === CleaningSizeCategory.STUDIO
          )?.amount_brutto || 0,
        apartment:
          data.rates.find(
            (rate) => rate.size_category === CleaningSizeCategory.APARTMENT
          )?.amount_brutto || 0,
        suite:
          data.rates.find(
            (rate) => rate.size_category === CleaningSizeCategory.SUITE
          )?.amount_brutto || 0,
        bigSuite:
          data.rates.find(
            (rate) => rate.size_category === CleaningSizeCategory.BIG_SUITE
          )?.amount_brutto || 0,
      });
    }
  }, [data, setFormValues]);

  return (
    <Flex direction="column">
      {loading && (
        <Flex direction={"column"} grow={1}>
          <Loader></Loader>
        </Flex>
      )}

      {data && !loading && (
        <Flex direction={"column"} gap={16}>
          <Heading>Ceny za sprzątanie.</Heading>
          <TextMuted>
            Dla pracowników: wynagrodzenie brutto. Dla firm: cena netto.
          </TextMuted>
          <Flex direction={"column"} gap={0}>
            <TextField
              min={0}
              type="number"
              label={"Studio"}
              value={formValues.studio}
              onChange={(event) => {
                setFieldValue("studio", Number(event.target.value));
              }}
            ></TextField>
          </Flex>

          <Flex direction={"column"} gap={0}>
            <TextField
              min={0}
              type="number"
              label={"Apartment"}
              value={formValues.apartment}
              onChange={(event) => {
                setFieldValue("apartment", Number(event.target.value));
              }}
            ></TextField>
          </Flex>

          <Flex direction={"column"} gap={0}>
            <TextField
              min={0}
              type="number"
              label={"Suite (<= 70m2)"}
              value={formValues.suite}
              onChange={(event) => {
                setFieldValue("suite", Number(event.target.value));
              }}
            ></TextField>
          </Flex>

          <Flex direction={"column"} gap={0}>
            <TextField
              min={0}
              type="number"
              label={"Big Suite (<= 100m2)"}
              value={formValues.bigSuite}
              onChange={(event) => {
                setFieldValue("bigSuite", Number(event.target.value));
              }}
            ></TextField>
          </Flex>

          <Flex marginTop={32}>
            <Button
              onClick={async () => {
                if (valid) {
                  setSubmitting(true);

                  try {
                    await handleUpdate(formValues);

                    setDirty(false);
                    load({ userId });
                    onSaved();
                    setSubmitting(false);
                  } catch (err) {
                    setSubmitting(false);
                  }
                }
              }}
              isDisabled={!valid && !dirty && !submitting}
              variation="primary"
              grow={1}
              disabled={!valid && !dirty && !submitting}
            >
              Save
            </Button>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
