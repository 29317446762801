import {
  Alert,
  Autocomplete,
  Button,
  FieldGroupIcon,
  Flex,
  Loader,
  SelectField,
  TextField,
} from "@aws-amplify/ui-react";
import { useState } from "react";
import { MdCancel, MdCheck, MdEdit, MdInfo } from "react-icons/md";
import { UserUpdateDto } from "../../shared/hooks/useUserData";
import BadgeField from "../../shared/components/BadgeField";
import { useCognitoUsers } from "../../shared/hooks/useCognitoUsers";
import { UserDetails } from "../../shared/types/user-details";

export default function UserDetailsForm({
  data,
  saveData,
  isSaving,
  savingError,
  savingSuccess,
}: {
  data: UserDetails;
  saveData: (userDetails: UserUpdateDto) => void;
  isSaving: boolean;
  savingError: Error | undefined;
  savingSuccess: boolean;
}) {
  const {
    data: cognitoUsers,
    error: cognitoError,
    loading: cognitoLoading,
  } = useCognitoUsers();

  const [formValues, setFormValues] = useState({
    email: data.email,
    first_name: data.first_name,
    last_name: data.last_name,
    address_line: data.address_line,
    zip_code: data.zip_code,
    city: data.city,
    country: data.country,
    language: data.language,
    company_name: data.company_name,
    vat_id: data.vat_id,
  });

  const updateFormValues = (key: keyof typeof formValues, value: string) =>
    setFormValues({
      ...formValues,
      [key]: value,
    });

  const getOnChangeHandler =
    (field: keyof typeof formValues) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      updateFormValues(field, event.target.value);
    };

  const getIcon = (readOnly = false) => (
    <FieldGroupIcon>{readOnly ? <MdInfo /> : <MdEdit />}</FieldGroupIcon>
  );

  return (
    <Flex direction={"column"} gap={20} width={"100%"}>
      <TextField
        label={"User ID"}
        value={data.id}
        readOnly={true}
        innerStartComponent={getIcon(true)}
      />

      {!cognitoLoading && !cognitoError && (
        <Autocomplete
          label="Email address"
          labelHidden={false}
          isLoading={!cognitoUsers}
          value={formValues.email}
          hasError={formValues.email === ""}
          errorMessage="Please select registered email address from the list"
          onChange={(event) => {
            updateFormValues("email", event.target.value);
          }}
          onSelect={(option) => {
            updateFormValues("email", option.id);
          }}
          onClear={() => {
            updateFormValues("email", "");
          }}
          options={(cognitoUsers || []).map((user) => ({
            id: user.email,
            label: user.email,
          }))}
        />
      )}

      {!cognitoLoading && cognitoError && (
        <Alert variation="error">
          Can not load cognito accounts. {cognitoError.message}
        </Alert>
      )}

      {cognitoLoading && <Loader variation="linear" />}

      <TextField
        label={"First Name"}
        value={formValues.first_name}
        onChange={getOnChangeHandler("first_name")}
        innerStartComponent={getIcon()}
      />

      <TextField
        label={"Last Name"}
        value={formValues.last_name}
        onChange={getOnChangeHandler("last_name")}
        innerStartComponent={getIcon()}
      />

      <TextField
        label={"Address Line"}
        value={formValues.address_line}
        onChange={getOnChangeHandler("address_line")}
        innerStartComponent={getIcon()}
      />

      <TextField
        label={"ZIP Code"}
        value={formValues.zip_code}
        onChange={getOnChangeHandler("zip_code")}
        innerStartComponent={getIcon()}
      />

      <TextField
        label={"City"}
        value={formValues.city}
        onChange={getOnChangeHandler("city")}
        innerStartComponent={getIcon()}
      />

      <TextField
        label={"Country"}
        value={formValues.country}
        onChange={getOnChangeHandler("country")}
        innerStartComponent={getIcon()}
      />

      <SelectField
        label={"Language"}
        value={formValues.language}
        onChange={getOnChangeHandler("language")}
      >
        <option value="EN">English</option>
        <option value="PL">Polish</option>
      </SelectField>

      <TextField
        label={"Company Name"}
        value={formValues.company_name}
        onChange={getOnChangeHandler("company_name")}
        innerStartComponent={getIcon()}
      />

      <TextField
        label={"VAT ID"}
        value={formValues.vat_id}
        onChange={getOnChangeHandler("vat_id")}
        innerStartComponent={getIcon()}
      />

      <BadgeField
        label="Management Contracts"
        badges={data.mgmt_contract_ids.map((id) => ({
          displayValue: `mgmt${id}`,
          link: `/contract/details/mgmt/${id}`,
        }))}
      />

      <BadgeField
        label="Invest Offers"
        badges={data.invest_offer_ids.map((id) => ({
          displayValue: `offer${id}`,
        }))}
      />

      <BadgeField
        label="Invest Contracts"
        badges={data.invest_contract_ids.map((id) => ({
          displayValue: `invest${id}`,
          link: `/contract/details/invest/${id}`,
        }))}
      />

      {!isSaving && savingError && (
        <Alert
          marginTop={5}
          variation="error"
          isDismissible={true}
          hasIcon={true}
          heading={"Can't save user"}
        >
          {savingError.message}
        </Alert>
      )}

      {!isSaving && savingSuccess && (
        <Alert
          marginTop={5}
          variation="success"
          isDismissible={true}
          hasIcon={true}
          heading={"Successfully updated user"}
        ></Alert>
      )}

      <Flex direction={"row"}>
        <Button
          variation="primary"
          gap={"0.5rem"}
          onClick={() => saveData(formValues)}
          isDisabled={isSaving || formValues.email === ""}
          isLoading={isSaving}
        >
          <MdCheck /> Save
        </Button>
        <Button gap={"0.5rem"} onClick={() => window.location.reload()}>
          <MdCancel /> Reset
        </Button>
      </Flex>
    </Flex>
  );
}
