import {
  Alert,
  Badge,
  Divider,
  Flex,
  Image,
  Text,
} from "@aws-amplify/ui-react";
import { groupBy } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import i18n from "../../i18n/i18n";
import Box from "../shared/components/Box";
import Headline from "../shared/components/Headline";
import { useUserProfile } from "../shared/components/UserProfileProvider";
import {
  CoreCoordinationDraftTask,
  CoreCoordinationTask,
  TaskProgressStatus,
} from "../shared/hooks/core-coordination-task";
import { useTasksApi } from "../shared/hooks/useTasksApi";
import { dateToYYMMDD } from "../shared/utils/dates.util";
import CleaningSchedulePlaceholder from "./CleaningSchedulePlaceholder";
import DaySelector from "./components/DaySelector";
import TaskCard from "./components/TaskCard";
import ScheduleColumn from "./ScheduleColumn";
import TaskDetailsModal from "./TaskDetailsModal";
import TextMuted from "../shared/components/TextMuted";

export default function ServiceRepSchedulePage() {
  // use "en" locale in admin pages
  moment.locale(i18n.language);

  const { t } = useTranslation();
  const { data, loading, error, load } = useTasksApi();
  const user = useUserProfile();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentDate, setDate] = useState(
    searchParams.get("date") ? moment(searchParams.get("date")) : moment()
  );
  const [selectedTask, setSelectedTask] = useState<
    Pick<CoreCoordinationTask, "task_id"> | undefined
  >(
    searchParams.get("taskId")
      ? { task_id: searchParams.get("taskId")! }
      : undefined
  );

  useEffect(() => {
    if (user.userProfile) {
      load({ date: dateToYYMMDD(currentDate), userId: user.userProfile.id });
    }

    if (currentDate) {
      searchParams.set("date", currentDate.format("YYYY-MM-DD"));
    }

    setSearchParams(searchParams);
  }, [currentDate, setSearchParams, load, searchParams, user.userProfile]);

  const tasks = useMemo(() => {
    if (!data) {
      return;
    }

    const timeGroups = groupBy(data.tasks, "scheduled_time");

    return {
      timeGroups,
    };
  }, [data]);

  const handleTaskSelection = useCallback(
    (task: CoreCoordinationDraftTask | CoreCoordinationTask) => {
      setSelectedTask({ task_id: task.task_id });
      setSearchParams({ ...searchParams, taskId: task.task_id });
    },
    [setSearchParams, setSelectedTask, searchParams]
  );

  const allDayTasks = useMemo(() => {
    if (!tasks) {
      return [];
    }

    return [
      ...(tasks.timeGroups["undefined"] || []),
      ...(tasks.timeGroups["null"] || []),
    ].sort((a, b) => {
      return a.progress_status === TaskProgressStatus.Planned ? 0 : -1;
    });
  }, [tasks]);

  return (
    <>
      <Flex direction={"column"} grow={1} overflow={"hidden"}>
        <Flex justifyContent={"space-between"}>
          <Headline>{t("Service.MyTasks")}</Headline>
        </Flex>

        {error && (
          <Box>
            <Alert variation="error" isDismissible={true} hasIcon={true}>
              {error.message}
            </Alert>
          </Box>
        )}

        <DaySelector currentDate={currentDate} setDate={setDate} />

        {loading && <CleaningSchedulePlaceholder />}

        {!loading && tasks && (
          <>
            {data && (
              <>
                {data.tasks.length > 0 && (
                  <Flex marginTop={35} alignItems={"center"}>
                    <Text fontWeight={"bold"}>{t("Service.Schedule")}</Text>
                    <Badge>
                      {t("Service.Tasks")} {data.tasks.length}
                    </Badge>
                  </Flex>
                )}

                <Divider></Divider>
                <Flex
                  grow={1}
                  direction={"row"}
                  justifyContent={"flex-start"}
                  overflow={"scroll"}
                >
                  {allDayTasks.length > 0 && (
                    <ScheduleColumn
                      onClick={(task) => handleTaskSelection(task)}
                      tasks={allDayTasks}
                      label={"All day"}
                    ></ScheduleColumn>
                  )}
                  {Object.keys(tasks.timeGroups)
                    .sort()
                    .filter(
                      (timeGroup) =>
                        timeGroup !== "undefined" && timeGroup !== "null"
                    )
                    .map((timeGroup) => (
                      <Flex direction={"column"} grow={1} basis={0}>
                        <Text marginBottom={16}>
                          {timeGroup}{" "}
                          <Badge>{tasks.timeGroups[timeGroup].length}</Badge>
                        </Text>
                        <Flex direction={"column"} margin={8}>
                          {tasks.timeGroups[timeGroup].map((task, index) => (
                            <TaskCard
                              onClick={() => handleTaskSelection(task)}
                              key={task.task_id}
                              task={task}
                            />
                          ))}
                        </Flex>
                      </Flex>
                    ))}
                </Flex>

                {!loading && !error && data.tasks.length === 0 && (
                  <Flex
                    direction={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Image
                      margin="0 auto"
                      width={400}
                      src={"/service/service-no-items.jpg"}
                      alt={t("Service.NoTasksAssigned")}
                    ></Image>
                    <TextMuted>{t("Service.NoTasksAssigned")}</TextMuted>
                  </Flex>
                )}
              </>
            )}
          </>
        )}

        {selectedTask && (
          <TaskDetailsModal
            onChanged={() => {
              if (user.userProfile) {
                load({
                  date: dateToYYMMDD(currentDate),
                  userId: user.userProfile.id,
                });
              }
            }}
            taskId={selectedTask.task_id}
            onClose={() => {
              setSelectedTask(undefined);
            }}
          ></TaskDetailsModal>
        )}
      </Flex>
    </>
  );
}
