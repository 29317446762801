import { UserDocument } from "../../Docs/UserDocument";
import { UserDetails } from "../types/user-details";
import { API } from "../utils/API";
import { ReportTable } from "./common";
import { CorePropertyData } from "./core-property";
import { useLazyApi } from "./useLazyApi";

export interface PropertyDetails<IsInvestProperty extends boolean> {
  internal_id: number;
  airbnb_url: string;
  booking_url: string;
  google_url: string;
  vrbo_url: string;
  hostaway_id: number;
  name: string;
  is_mgmt_property: boolean;
  assigned_mgmt_contracts: number[];
  is_invest_property: boolean;
  invest_property_data: IsInvestProperty extends true
    ? {
        appartmentSize: number;
        totalFinancingAmount: number;
        expectedPricePerNight: number;
        expectedRoi: number;
        currency: string;
      }
    : null;
  assigned_invest_contracts: number[];
  disabled: boolean;
  checkin_instructions: string;
  checkin_instructions_pl: string;
  past_checkin_instructions: string;
  past_checkin_instructions_pl: string;
  parking_info: string;
  parking_info_pl: string;
  door_code: string;
  address: string;
  floor: string;
  users: Omit<UserDetails, "email">[];
  docs: UserDocument[];
}

const getProperties = async (): Promise<PropertyDetails<boolean>[]> =>
  await API.get(`/v1/admin/contracts/properties`);

const getProperty = async (
  internalId: string
): Promise<PropertyDetails<boolean>> =>
  await API.get(`/v1/admin/contracts/properties/${internalId}`);

const getPropertyByHostawayId = async (
  hostawayId: number
): Promise<PropertyDetails<boolean>> =>
  await API.get(`/v1/admin/contracts/properties/hostaway/${hostawayId}`);

const updateProperty = async (
  internalId: number,
  body: PropertyDetails<boolean>
): Promise<PropertyDetails<boolean>> =>
  await API.put(`/v1/admin/contracts/properties/${internalId}`, {
    body,
  });

export const updatePropertyPmsId = async (
  internalId: number,
  body: Pick<PropertyDetails<boolean>, "hostaway_id">
): Promise<PropertyDetails<boolean>> =>
  await API.put(`/v1/admin/contracts/properties/${internalId}/hostaway_id`, {
    body,
  });

export const useLazyProperties = () => {
  return useLazyApi<
    ReportTable<CorePropertyData, undefined>,
    {
      query?: string;
      status?: "active" | "all" | "disabled" | "unassigned" | null;
      city?: string;
    }
  >(`/v1/admin/properties`);
};

export const useProperties = () => {
  return {
    getPropertyByHostawayId,
    getProperty,
    getProperties,
    updateProperty,
  };
};
