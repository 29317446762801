import { Alert, Flex } from "@aws-amplify/ui-react";
import { useState } from "react";
import GenericTable from "../shared/GenericTable";
import GenericTablePage from "../shared/GenericTablePage";
import { usePropertyTasksApi } from "../shared/hooks/usePropertyTasksApi";
import TaskDetailsModal from "./TaskDetailsModal";
import { CoreCoordinationTask } from "../shared/hooks/core-coordination-task";

export default ({ propertyId }: { propertyId: number }) => {
  const { loading, data, error, refetch } = usePropertyTasksApi(propertyId);
  const [selectedTask, setSelectedTask] = useState<
    Pick<CoreCoordinationTask, "task_id"> | undefined
  >(undefined);

  return (
    <GenericTablePage>
      {error && (
        <Flex>
          <Alert
            grow={1}
            variation="error"
            isDismissible={false}
            hasIcon={true}
          >
            {error.message}
          </Alert>
        </Flex>
      )}

      {data && (
        <GenericTable
          onSelected={(item) => {
            setSelectedTask({ task_id: item.task_id });
          }}
          data={{ columns: data.columns, sums: [], items: data.items }}
          loading={loading}
        ></GenericTable>
      )}

      {selectedTask && (
        <TaskDetailsModal
          onChanged={() => {
            refetch();
          }}
          taskId={selectedTask.task_id}
          onClose={() => {
            setSelectedTask(undefined);
          }}
        ></TaskDetailsModal>
      )}
    </GenericTablePage>
  );
};
