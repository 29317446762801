import { DeepNullable } from "./common";
import { PropertyImage } from "./usePropertyImages";

export enum CoordinationTaskType {
  Cleaning = "Cleaning",
  Delivery = "Delivery",
  Inspection = "Inspection",
  Maintenance = "Maintenance",
  Custom = "Custom",
}

export enum TaskProgressStatus {
  Planned = "planned",
  Started = "started",
  Completed = "completed",
  Cancelled = "cancelled",
}

export type CoordinationTask = CoreCoordinationTask | CoreCoordinationDraftTask;

export interface CoreCoordinationTask {
  task_id: string;
  user_name?: string;
  template_id?: number;
  notes?: string;
  address?: string;
  progress_status?: TaskProgressStatus;
  user_id?: number;
  type?: CoordinationTaskType;
  scheduled_date?: string;
  scheduled_time?: string | null;
  duration: string | null;
  property_id?: number;
  city: string;
  sameDayCheckinExists: boolean;
}

export interface CoreCoordinationDraftTask extends CoreCoordinationTask {
  address?: string;

  // fn
  type: CoordinationTaskType;
  is_prolonged: boolean;
  number_of_guests: number;
  hostaway_listing_id: number;
  sortId: number;
  reservation_ref: string;
  hostaway_reservation_id: string;

  // flag only set if proposed, other undefined
  is_system_proposed?: boolean;
}

export type UpdateCoreCoordinationTaskParams = Partial<
  DeepNullable<CoreCoordinationTask>
>;

export interface CoreCoordinationTaskWithImages extends CoreCoordinationTask {
  images: PropertyImage[];
}
