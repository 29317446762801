import { API } from "../utils/API";
import { prepareHerokuRequest, unmarshallDates } from "./common";

export interface IStatementProposal {
  account_id: string;
  account_mail: string;
  contract_id: number;
  contract_type: "invest" | "mgmt";
  end_date_statement: Date;
  grand_total: string;
  hostaway_listing_id: number;
  participation_rate: string | null;
  passed_plausibility: boolean;
  payout_base: string;
  payout_id: string;
  pdf_url: string;
  pm_commission: string;
  prorated_payout: string | null;
  published_status: null | "Planned" | "Paid";
  rental_income: string;
  reservation_portal_fee: string;
  start_date_statement: Date;
  statement_title: string;
  statement_type: "INVESTOR" | "OWNER";
  status: "new" | "modified" | "approved" | "planned";
  total_expenses: string;
  total_tax: string;
  utility_cost: number;
  vat_id: string | null;
}

const getProposals = async (
  from: string,
  to: string
): Promise<{
  stats: { name: string; value: number }[];
  proposals: IStatementProposal[];
  missing: number[];
}> => {
  const data = await API.get(
    `/v1/admin/payouts/proposals?from=${from}&to=${to}`,
    await prepareHerokuRequest()
  );

  const proposals = data.items.map((row: any) =>
    unmarshallDates(row, ["end_date_statement", "start_date_statement"])
  );

  return { stats: data.stats, proposals, missing: data.missing };
};

/**
 * Synchronize owner statement proposals from Hostaway.
 * You need to provide `from` and `to` parameters to select all proposals where start and end date of proposal is within the given range.
 *
 * @param from
 * @param to
 */
const syncProposals = async (from: string, to: string): Promise<void> =>
  API.post(
    "/v1/admin/backend-jobs/manager",
    await prepareHerokuRequest({
      body: {
        type: "SyncPayoutProposals",
        jobParams: {
          from: from,
          to: to,
        },
      },
    })
  );

/**
 * Update owner statement proposal
 */
const updateProposal = async (proposal: IStatementProposal): Promise<void> =>
  API.put(
    `/v1/admin/payouts/proposals`,
    await prepareHerokuRequest({
      body: proposal,
    })
  );

const approveProposal = async (payoutId: string): Promise<void> =>
  API.put(
    `/v1/admin/payouts/proposals/approve/${payoutId}`,
    await prepareHerokuRequest()
  );

/**
 * Publish owner statement proposal
 */
const publishProposal = async (payoutID: string): Promise<void> =>
  API.post(
    `/v1/admin/payouts/published/${payoutID}`,
    await prepareHerokuRequest()
  );

export const useOwnerStatements = () => {
  return {
    getProposals,
    syncProposals,
    publishProposal,
    updateProposal,
    approveProposal,
  };
};
