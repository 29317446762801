import {
  Badge,
  Flex,
  Menu,
  MenuItem,
  Button,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
import { clearAllCache } from "../../hooks/useCache";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useLocation, useNavigate } from "react-router-dom";
import { MdLogout, MdPerson, MdSettings } from "react-icons/md";
import { theme } from "../../../../theme";

export default () => {
  const { signOut, user } = useAuthenticator((context) => [context.user]);
  const { t } = useTranslation();
  const { groups } = useCurrentUser();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Flex
      style={{ textAlign: "right", flexGrow: 1, gap: 24 }}
      className="noprint"
      justifyContent={"flex-end"}
    >
      <Flex alignItems={"center"}>
        <Badge marginRight={8}>{groups.join(", ")}</Badge>
      </Flex>

      <Flex>
        <Menu
          trigger={
            <Button
              variation={"link"}
              size="large"
              backgroundColor={
                location.pathname.startsWith("/settings")
                  ? theme.accentColor
                  : undefined
              }
            >
              <MdSettings
                color={
                  location.pathname.startsWith("/settings")
                    ? theme.primaryColor
                    : undefined
                }
              ></MdSettings>
            </Button>
          }
        >
          <MenuItem
            onClick={() => navigate("/settings/user")}
            backgroundColor={
              location.pathname === "/settings/user"
                ? theme.accentColor
                : undefined
            }
          >
            <Flex alignItems={"center"}>
              <Flex>
                <MdPerson
                  color={
                    location.pathname.startsWith("/settings")
                      ? theme.primaryColor
                      : undefined
                  }
                />
              </Flex>
              <Flex>My Profile</Flex>
            </Flex>
          </MenuItem>

          {!!user && !!signOut && (
            <MenuItem
              onClick={() => {
                clearAllCache();
                signOut();
              }}
            >
              <Flex alignItems={"center"}>
                <Flex>
                  <MdLogout />
                </Flex>
                <Flex>{t("Logout")}</Flex>
              </Flex>
            </MenuItem>
          )}
        </Menu>
      </Flex>
    </Flex>
  );
};
