import { Alert, Flex } from "@aws-amplify/ui-react";
import { useState } from "react";
import GenericTable from "../shared/GenericTable";
import GenericTablePage from "../shared/GenericTablePage";
import AddButton from "../shared/components/AddButton";
import Box from "../shared/components/Box";
import {
  deleteFromServiceTeam,
  useServicePeople,
} from "../shared/hooks/service/useServicePeople";
import AddUserToGroup from "./AddUserToGroup";
import InviteNewUser from "./InviteNewUser";
import ConfirmModal from "../shared/ConfirmModal";
import EditServiceUserModal from "./EditServiceUserModal";
import { useTranslation } from "react-i18next";

export default () => {
  const { t } = useTranslation();
  const { error, loading, refetch, data } = useServicePeople();
  const [addingNew, setAddingNew] = useState<boolean>(false);
  const [invitingNew, setInvitingNew] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<
    { user_id: number } | undefined
  >();
  const [userToBeDeleted, setUserToBeDeleted] = useState<
    { user_id: number } | undefined
  >();

  return (
    <>
      <GenericTablePage
        title={t("Service.ServiceTeamTitle")}
        subtitle={t("Service.ServiceTeamSubtitle")}
      >
        <Flex marginBottom={35} justifyContent={"flex-start"}>
          <AddButton
            label="Add Member"
            onClick={() => {
              setAddingNew(true);
            }}
          ></AddButton>
          <AddButton
            label="Invite new Member"
            onClick={() => {
              setInvitingNew(true);
            }}
          ></AddButton>
        </Flex>
        <Flex direction={"column"}>
          {error && (
            <Box>
              <Alert variation="error">{error.message}</Alert>
            </Box>
          )}

          <Box>
            <GenericTable
              loading={loading}
              data={data}
              onSelected={(user) => {
                setSelectedUser(user);
              }}
            ></GenericTable>
          </Box>
        </Flex>
      </GenericTablePage>

      {userToBeDeleted && (
        <ConfirmModal
          title="Delete from Service Team"
          onClose={() => {
            setUserToBeDeleted(undefined);
          }}
          onConfirmed={async () => {
            await deleteFromServiceTeam(userToBeDeleted.user_id);

            setSelectedUser(undefined);
            setUserToBeDeleted(undefined);

            refetch();
          }}
        />
      )}

      {selectedUser && (
        <EditServiceUserModal
          userId={selectedUser.user_id}
          onClose={(updated) => {
            if (updated) {
              refetch();
            }

            setSelectedUser(undefined);
          }}
          onDelete={async () => {
            setUserToBeDeleted(selectedUser);
          }}
        />
      )}

      {userToBeDeleted && (
        <ConfirmModal
          title="Delete from Service Team"
          onClose={() => {
            setUserToBeDeleted(undefined);
          }}
          onConfirmed={async () => {
            await deleteFromServiceTeam(userToBeDeleted.user_id);

            setUserToBeDeleted(undefined);
            setSelectedUser(undefined);
            refetch();
          }}
        />
      )}

      {addingNew && (
        <AddUserToGroup
          onClose={() => {
            setAddingNew(false);
            refetch();
          }}
        ></AddUserToGroup>
      )}

      {invitingNew && (
        <InviteNewUser
          onClose={() => {
            setInvitingNew(false);
            refetch();
          }}
        ></InviteNewUser>
      )}
    </>
  );
};
