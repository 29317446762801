import { Flex, SelectField, Text } from "@aws-amplify/ui-react";
import { useSearchParams } from "react-router-dom";
import { useSearchParamsUtils } from "../hooks/useSearchParamsUtils";

export default ({
  filters,
  loading,
}: {
  loading: boolean;
  filters: {
    label: string;
    key: string;
    options: {
      value: string | undefined;
      label: string;
    }[];
  }[];
}) => {
  const [searchParams] = useSearchParams();
  const { setSingleSearchParam } = useSearchParamsUtils();

  return (
    <Flex direction="column" grow={1}>
      <Flex direction="row" gap={50} justifyContent={"space-between"}>
        <Flex direction={"row"} gap={8}>
          {filters.map((filter) => (
            <Flex direction={"column"} gap={0}>
              <Text textAlign={"flex-start"}>{filter.label}</Text>
              <SelectField
                labelHidden={true}
                label=""
                size="small"
                value={String(searchParams.get(filter.key)!)}
                disabled={loading}
                onChange={(e) => {
                  setSingleSearchParam(filter.key, e.target.value, true);
                }}
              >
                {filter.options.map((option) => (
                  <option
                    key={`${filter.key}-${option.value}`}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </SelectField>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};
