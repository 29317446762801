export const en = {
  translation: {
    TODO: "TODO",
    ReservationId: "Reservation ID",
    OwnerPayout: "Payout",
    Help: "Help",
    Service: {
      NoCurrentReservation: "No current reservation",
      CurrentReservation: "Current reservation",
      Listing: "Listing",
      CancelTask: "Cancel task",
      TaskDueOn: "Due on",
      ScheduledAt: "Scheduled at",
      ServiceTeamTitle: "Service Team",
      ServiceTeamSubtitle:
        "Members of the service team (cleaning staff, technicians, inspectors) that can be assigned to coordination tasks.",
      NoTasksForSelection: "No tasks for selected criteria",
      Properties: "Properties",
      ServiceCockpit: "Service",
      MyReport: "My Report",
      Coordination: "Coordination",
      NoNextReservations: "No upcoming reservations",
      NoDeparturesOnThisDay: "No departures on this day",
      SameDayTurnOver: "Same-day checkin",
      DepartingReservation: "Ending reservation",
      NextReservation: "Next reservation",
      NotesPlaceholder: "Add notes here",
      ManagePeople: "Manage People",
      Tasks: "Tasks",
      TaskOverview: "Overview",
      TaskPropertyAccess: "Access",
      TaskRequirements: "Requirements",
      TaskActivity: "Activity",
      PlanSchedule: "Schedule",
      AssignedUser: "Assigned user",
      Notes: "Notes",
      Property: "Property",
      TaskType: "Task Type",
      Cancelled: "Cancelled",
      NoTasksAssigned: "No tasks assigned to you",
      Draft: "Draft",
      Schedule: "Schedule",
      Maintenance: "Maintenance",
      Cleaning: "Cleaning",
      Inspection: "Inspection",
      Delivery: "Delivery",
      MyTasks: "My tasks",
      CleaningSchedule: "Cleaning Schedule",
      AddTask: "Add Task",
      SameDayCheckin: "Same day check-in",
      StartTask: "Start Task",
      DeleteTask: "Delete Task",
      MarkAsFinished: "Mark as finished",
      RequirementsInfo:
        "You must fullfill all requirements before you can mark this task as done.",
    },
    Common: {
      Save: "Save",
    },
    Invoice: {
      InvalidPostalCode: "Invalid postal code for the selected country",
      BeforeCheckoutAlert:
        "You can request an invoice either on the checkout date or afterwards. Please feel free to ask for the invoice at a later time.",
      NoPaymentAlert:
        "The reservation is not fully paid. You cannot request invoice before payment.",
      InvalidInput: "Invalid Input",
      Required: "Missing input",
      NotAvailable: "Late Checkout Unavailable",
      Title: "Request VAT Invoice",
      Subtitle: "Request VAT Invoice for your stay with RENTUJEMY",
      Cancel: "The payment failed. Would you like to try again?",
      InputVatLabel:
        "Please specify NIP (for polish companies) or VAT ID (for foreign companies)",
      InputCompanyName: "Company name",
      Success: "Success",
      Submit: "Request",
      GenerationError:
        "A VAT invoice generation error has occurred. Correct the data and try again.",
    },
    FinalizeBooking: {
      Title: "Finalize Booking",
      Subtitle:
        "Your reservation is not paid yet. Note that as long the reservation is not paid, we cannot guarantee the reservation. In order to confirm it, finalize your booking now.",
      Submit: "Proceed to Payment",
      SuccessTitle: "Your Reservation is Confirmed",
    },
    PortalFees: "Channel Commission",
    ManagementCommission: "Rentujemy Comission",
    RentujemyLogo: "Rentujemy logo",
    YourApartment: "Your Apartment",
    PaymentId: "Payment ID",
    BankAccount: "Bank account",
    City: "City",
    TotalTax: "VAT & City Taxes",
    Invest: "Invest",
    Search: "Search",
    ChooseContract: "Choose Contract",
    OwnerCockpit: "Owner's Cockpit",
    CoordinatorCockpit: "Coordinator Cockpit",
    Tags: "Tags:",
    Links: "Links:",
    More: "More...",
    PropertyImage: "Property image",
    Address: "Address:",
    Reservations: "Reservations",
    OfferDescription: "Offer description",
    CheckIn: "Check in",
    CheckOut: "Check out",
    Channel: "Channel",
    TotalPrice: "Total Price",
    Other: "Other",
    MyContracts: "My contracts",
    AllContracts: "All contracts",
    MyClearingReports: "My settlements",
    SeeInvestProjects: "See all projects",
    Airbnb: "AIRBNB",
    Booking: "BOOKING",
    Stay: "STAY.RENTUJEMY.PL",
    AcceptTerms: {
      AcceptTerms:
        "By clicking 'Create account', you confirm that you have read and accepted",
      TermsHyperlink:
        "the terms and conditions of the internet platform invest.rentujemy.pl.",
    },
    Account: {
      MyData: "My personal data",
      DeleteAccount:
        "If you want to delete your account, write a request to wlasiciele@rentujemy.pl",
      City: "City",
      Country: "Country",
      FirstName: "First name",
      Language: "Language",
      LastName: "Last name",
      PersonalDocumentId: "Personal Document ID",
      Street: "Street",
      StreetNo: "Street / House number",
      Subtitle: "",
      TaxId: "Tax Id",
      UserId: "User ID",
      ZipCode: "Zip Code",
    },
    ContractType: "Investment Type",
    Counterparty: "Contract Partner",
    "Dalej do danych umowy": "Continue to contracts",
    "Do mojego inwestycjnego kokpitu": "To Portfolio",
    "Do ostatecznego potwierdzenia inwestycji": "To final confirmation",
    EarliestCancellationDate: "Earliest cancellation date",
    FixedInterestRate: "Fixed Interest Rate",
    InvestedAmount: "Investment Amount",
    InvestmentAmountContent: {
      HowIsItCalculated: "How was it calculated?",
      CurrencyConversionDisclaimer:
        "The EUR amount will be converted to PLN currency upon successful acceptance of the offer. Payouts will be converted to your account on the payment date with the respective rate.",
      InvestInEUR: "Invest with EUR",
      InvestInPLN: "Invest with PLN",
      MonthlyIncomeDisclaimer:
        "*calculated based on assumed variable interest rate depending on income from renting the property. Here 8% was assumed.",
      MonthlyIncomeHeadline:
        "variable interest rate paid out to your account monthly *",
      YearlyIncomeDisclaimer:
        "*calculated based on assumed variable interest rate depending on income from renting the property. Here {{roi}}% was calculated.",
      YearlyIncomeHeadline:
        "Expected yearly amount, with monthly payout to your account *",
    },
    InvestmentConfirm: {
      ConfirmInvestmentInfo:
        "Hiermit bestätige ich, dass ich die Vertragsunterlagen soeben erhalten und gelesen habe. Ich verstehe das mit meiner Investition verbundene Risiko.",
      ConfirmNetWorthInfo:
        "Hiermit bestätige ich, dass mein durchschnittliches monatliches Nettoeinkommen mindestens 10.000 PLN beträgt oder dass ich über ein Vermögen von mindestens 500.000 PLN verfüge, über das ich frei verfügen kann.",
      ConfirmOwnIdentity:
        "I confirm that I do the investment in my own name and for my own account.",
      ConfirmTermsOfService: "I agree to the terms of service",
      Headline: "Confirmation of your investment offer",
    },
    InvestmentContractContent: {
      EnsureYouKnowTheRisks: `Stelle sicher, dass du wichtige Informationen über das Projekt kennst. Alle Investitionen haben das Risiko Kapital zu verlieren. Das Unternehmen Rentujemy Sp. z o.o. listet einige Risiken in seinen Unterlagen. Investiere verantwortungsvoll und mach dich stets mit allen Unterlagen vertraut, bevor du eine Entscheidung für eine Investition triffst.`,
      Headline: "Your investment contracts",
      SaveContracts:
        "Bitte speichere die folgenden Vertragsvorlagen für das Projekt:",
    },
    InvestmentSuccess: {
      Headline: "Vielen Dank für das Investitionsangebot.",
      Subtitle:
        "Vielen Dank für das Investitionsangebot, es wurde erfolgreich abgesendet. Innerhalb der nächsten Tage erhältst du von uns eine offizielle Vertragsbestätigung.",
    },
    InvestmentWireInvestment: {
      Headline: "Wire transfer",
      TransferCommitment:
        "Ich überweise den Betrag innerhalb von 5 Werktagen auf obiges Konto. Mir ist bekannt, dass Rentujemy Sp. z o.o. das Vertragsangebot ablehnen kann.",
      TransferNow:
        "Bitte überweise den Betrag unter Angabe des Verwendungszwecks umgehend auf unten stehendes Konto.",
    },
    GoToIncomeProjection: "See income projections",
    "Income Calculator": "Income Calculator",
    shortTermCalculator: {
      chooseCity: "Choose city:",
      chooseDistrict: "Choose district:",
      chooseRegion: "Choose region:",
      chooseType: "Choose apartment type:",
      chooseStandard: "Choose apartment standard:",
      studio: "Studio",
      twoRooms: "Two rooms",
      threeRooms: "Three rooms",
      twoRoomsAlternative: "2 rooms",
      threeRoomsAlternative: "3 rooms",
      comfort: "Comfort",
      premium: "Premium",
      Warsaw: "Warsaw",
      Lodz: "Lodz",
      Tricity: "Tricity",
    },
    ROICalculator: {
      CalculateROI: "Calculate ROI",
      Amount: "Enter the amount",
      TaxAmount: "Enter the tax rate",
      RentalIncome: "Rental income",
      UtilityCosts: "Property running costs",
      TaxRate: "Tax rate (%)",
      PropertyPurchasePrice: "Property purchase price",
      CheckOut: "Check out",
      ApartmentStandard: "Apartment standard",
      NoOfRooms: "Number of rooms",
      AdditionalRentalCosts:
        "From the above amount, the owner must pay a PIT-28 tax at a rate of 8%. Additionally, the owner is responsible for paying monthly fees for the apartment, water, electricity, and utilities.",
      OccupancyDisclaimer:
        "Familiarize yourself with the methods that Rentujemy uses to increase short-term rental occupancy.",
      IncomeDisclaimer:
        "Nightly earnings are a price determined by Rentujemy reduced by the service fees of booking portals, Rentujemy commission fees, and VAT tax. We do not deduct taxes from rental income or business operation costs. The actual amount of your earnings will depend on several factors such as availability, price, or demand in your area. Other factors may also affect actual earnings.",
      Title: "Owner's income calculator",
      OwnerSubtitle:
        "Real estate investment ROI calculator. Our calculator helps estimate the return on investment from renting out your apartment, taking into account its purchase price. It also considers factors such as rental income, taxes, and additional property maintenance costs to help you estimate profits. Depending on the result of the calculator, you will know whether your ROI is low, normal, or high. Rental profitability depends on various factors, and our calculator can help you estimate the ROI for your property.",
      CTA: "Looking for a property manager in Warsaw?",
      ROI: "ROI",
      PotentialAnalysis: "Income projection analysis",
      Value: "Value",
      PricePerNight: "Price per night",
      PricePerNightDisclaimer:
        "Learn what factors Rentujemy takes into account when setting rental prices.",
      IncomeSubtitle: "{{nights}} days rented for {{price}} PLN/day",
      OwnerPayout: "Payout to owners",
      IncomeAnalysis: "Income analysis",
      Calculation: "Calculation",
      ManagementFeesCosts: "Rentujemy Provision",
      ApartmentSize: "Apartment Size",
      TotalInvestmentValue: "Total investment amount",
      ExpectedPricePerNight: "Expected daily rental price",
      Occupation: "Occupancy",
      Subtitle: "Projected financial performance for '{{name}}'",
      MonthlyIncome: "Monthly revenue",
      Taxes: "Taxes (VAT 8%)",
      AfterUtilityCosts: "After utility costs",
      ReservationFeesCosts: "Reservation portal fees",
      AfterManagementFeesCosts: "After management fees",
      AfterReservationPortalCosts: "After reservation portal fees",
    },
    IncomeCalculator: {
      PayoutDisclaimer:
        "The results are estimates only and are provided for informational purposes only.",
      CheckOut: "Check out",
      ApartmentStandard: "Apartment standard",
      NoOfRooms: "Number of rooms",
      AdditionalRentalCosts:
        "From the above amount, the owner must pay a PIT-28 tax at a rate of 8%. Additionally, the owner is responsible for paying monthly fees for the apartment, water, electricity, and utilities.",
      OccupancyDisclaimer:
        "Familiarize yourself with the methods that Rentujemy uses to increase short-term rental occupancy.",
      IncomeDisclaimer:
        "Nightly earnings are a price determined by Rentujemy reduced by the service fees of booking portals, Rentujemy commission fees, and VAT tax. We do not deduct taxes from rental income or business operation costs. The actual amount of your earnings will depend on several factors such as availability, price, or demand in your area. Other factors may also affect actual earnings.",
      Title: "Owner's income calculator",
      OwnerSubtitle: "How much can you earn from short-term rentals?",
      CTA: "Looking for a property manager in Warsaw?",
      ROI: "ROI",
      PotentialAnalysis: "Income projection analysis",
      Value: "Value",
      PricePerNight: "Price per night",
      PricePerNightDisclaimer:
        "Learn what factors Rentujemy takes into account when setting rental prices.",
      IncomeSubtitle: "{{nights}} days rented for {{price}} PLN/day",
      OwnerPayout: "Payout to owners",
      IncomeAnalysis: "Income analysis",
      Calculation: "Calculation",
      ManagementFeesCosts: "Rentujemy Provision",
      ApartmentSize: "Apartment Size",
      TotalInvestmentValue: "Total investment amount",
      ExpectedPricePerNight: "Expected daily rental price",
      Occupation: "Occupancy",
      Subtitle: "Projected financial performance for '{{name}}'",
      MonthlyIncome: "Monthly revenue",
      Taxes: "Taxes (VAT 8%)",
      AfterUtilityCosts: "After utility costs",
      ReservationFeesCosts: "Reservation portal fees",
      AfterManagementFeesCosts: "After management fees",
      AfterReservationPortalCosts: "After reservation portal fees",
    },
    "Payout to investors": "Payout to investors",
    Inwestycja: "Investition",
    "Kokpit Inwestora": "Investor's Cockpit",
    Konto: "Konto",
    KID: "KID (Key Information Document)",
    "Kwota inwestycji": "Investment Amount",
    "Kwota Przelewu": "Wire transfer amount",
    ContractUrl: "Contract URL",
    MyInvests: "My active investments",
    MyOffers: "My Offers",
    "Moje Wypłaty": "My Payouts",
    Od: "From:",
    Do: "To:",
    "Całkowita kwota:": "Total amount:",
    All: "All",
    Paid: "Paid",
    Planned: "Planned",
    InvestObject: "Investment object",
    Kontrakt: "Kontrakt",
    BankName: "Name des Finanzinstituts",
    ReceiverName: "Name des Empfängers",
    NoActiveInvests: "There are no active investments yet.",
    NoInvestOffers: "There are no investment offers yet.",
    NoInvestPayouts: "There are no payouts yet.",
    NotConfirmed: "Not confirmed",
    Oczekujący: "Pending",
    Odrzucona: "Refused",
    "Oferta wysłana": "Angebotssendungsdatum",
    opcjonalne: "optional",
    "Ostatnia aktualizacja": "Last update",
    ParticipationInValueIncrease: "Participation in value growth",
    Payout: {
      TotalAmount: "Total Amount",
      AvgPayout: "Ø Payout",
      Amount: "Amount",
      FixedInterest: "Fixed interest",
      MixedInterest: "Fixed + Variable interest",
      OwnerPayout: "Owner payout",
      Paid: "Paid",
      WrongIBAN: "Please enter valid IBAN value",
      Planned: "Planned",
      PaidDate: "Wire date",
      SettlementStart: "Settlement start",
      Settlement: "Settlement",
      SettlementEnd: "Settlement end",
      PlannedPaymentDate: "Payout date",
      Principal: "Principal",
      Status: "Status",
      Type: "Type",
      VariableInterest: "Variable interest",
      IsFetching: "Fetching payouts data. Please wait.",
      IsSyncing: "Payouts data is synchronizing. Please wait.",
      Title: "Title",
    },
    PayoutAccount: {
      OwnerName: "Account owner",
      AccountCurrency: "Account Currency",
      ChooseAccountCurrency: "Choose your account currency",
      BICInfo: "Only required for not polish accounts.",
      WrongOwnerName: "Please enter owner name",
      NoAccounts: "No payout accounts yet",
      ContactSupport:
        "To Edit or Delete a payout account please contact support@rentujemy.pl",
      AddAccount: "Add Payout Account",
    },
    "Pobierz wzór umowy pożyczki": "Download contract",
    Confirm: "Confirm",
    "Potwierdzam przelew": "I confirm that I've done the wire",
    "Prześlij ofertę inwestycji": "Send investment offer",
    Payouts: "Payouts",
    ShareOfProjectIncome: "Beteiligung an Gewinn",
    "Status inwestycji": "Investitionsstatus",
    "Status płatności": "Zahlungsstatus",
    "Teraz Inwestuję": "Invest Now",
    "Tytuł przelewu": "Verwendungszweck",
    "W weryfikacji": "Wird verifiziert",
    "Wybierz inwestycje": "Choose investment amount",
    Logout: "Logout",
    Zaakceptowana: "Aktzeptiert",
    "Zapłać teraz": "Pay Now",
    PurposePayment: "Wire Purpose",
    BeneficiaryName: "Beneficiary Name",
    IBAN: "IBAN",
    BIC: "BIC",
    TransferAmount: "Transfer amount",
    CreateContract: "Create contract",
    Admin: "Admin",
    Impressum: "Impressum",
    Home: "Home",
    Investments: "Investments",
    PaymentStatus: {
      UnknownStatus: "UnknownStatus",
      Confirmed: "Confirmed",
      Declared: "Declared",
      Pending: "Pending",
    },
    UpdateOffer: "Update offer",
    AddPayout: "Add payout",
    Transferred: "Transferred",
    Auth: {
      EnterUsername: "Please enter username",
      EnterPassword: "Please enter password",
      EnterEmail: "Please enter email",
    },
    OwnerCockpitError: {
      SomeErrorCode: "Something went wrong, please try again later.",
      RefreshErrorCode: "Unable to refresh, data may be out of date.",
    },
    Calendar: {
      Calendar: "Calendar",
      Today: "today",
    },
    EarlyCheckin: {
      Title: "Early Check-In from 7AM",
      NotAvailable: "Early Check-In Unavailable",
      Cancel: "The payment failed. Would you like to try again?",
      Success: "Your late checkout until 3PM is confirmed",
      Subtitle:
        "Early Check-In - If you'd like to check in earlier from 7 AM, please fill out the following form.",
      Submit: "Request Early-Checkin",
    },
    LateCheckout: {
      NotAvailable: "Late Checkout Unavailable",
      Title: "Late Checkout at 3pm",
      Subtitle:
        "Stay relaxed until 3PM. Extend your checkout time by booking now.",
      Cancel: "The payment failed. Would you like to try again?",
      Success: "Your late checkout until 3PM is confirmed",
      Submit: "Order Late Checkout",
    },
    Reservation: {
      Id: "Reservation ID",
      NumberOfGuests: "Number of guests",
      CleaningFee: "Cleaning fee",
      ReservationTotal: "Reservation Total Value",
      Details: "Details",
      Cancelled: "Cancelled",
      OwnerStay: "Owner stay",
      OwnerReservation: "Owner reservation",
      IsSyncing: "Reservation data is synchronizing. It may take a while.",
      IsSyncingCheckLater:
        "Reservations data is synchronizing with reservation portals. Please check later.",
      NoData: "There is no reservation yet for this object. Check again later.",
      NoCheckoutsInThisMonth: "No checkouts this month.",
      OccupancyRate: "Occupancy",
      AverageDailyRate: "Ø Payout / Night",
      AverageDailyPrice: "Ø Price / Night",
      AdditionalCostDisclaimer:
        "The information is provided for informational purposes, but we make every effort to ensure it is as accurate as possible. Payouts are determined based on the settlement at the end of the month.",
      Notes: "Notes",
    },
    GuestReservation: {
      OpenBalanceAlert: "The reservation is not fully paid yet.",
      Reservation: "Reservation",
      NewCheckoutDate: "New Checkout Date",
      RequestInvoice: "Request VAT Invoice",
      Title: "Prolong Your Reservation",
      Notes:
        "Owner's reservations cannot conflict with current reservations and can last a maximum of 14 days. The reservation itself is free, however, after each reservation, an invoice will be issued for the cleaning service after the reservation. The reservation must be confirmed by Rentujemy.",
      SendRequest: "Check Availablity",
      FinalizeBooking: "Finalize Booking",
      FinalizePayment: "Finalize Payment",
      FailedMessage: "We are facing technical issue. Try again.",
      SuccessTitle: "The apartment is available to book!",
      SuccessMessage:
        "We have sent you an email so you can finalize your reservation. You can also click the button below to complete the reservation process.",
      PastDateError: "Dates must be in the future.",
      CheckoutBeforeCheckinError:
        "Checkout date cannot be before checkin date.",
      ReservationDurationError: "Reservation cannot be longer than 14 days.",
      GuestFirstName: "Guest first name",
      GuestLastName: "Guest last name",
      CheckinDate: "Checkin date",
      CheckoutDate: "Checkout date",
      NotAvailable: "The apartment is not available for the selected dates.",
    },
    OwnerReservation: {
      Title: "Create owner's reservation",
      Notes:
        "Owner's reservations cannot conflict with current reservations and can last a maximum of 14 days. The reservation itself is free, however, after each reservation, an invoice will be issued for the cleaning service after the reservation. The reservation must be confirmed by Rentujemy.",
      SendRequest: "Send request",
      FailedMessage: "Failed creating your reservation.",
      SuccessMessage:
        "Your reservation has been sent. If it does not conflict with other reservations, it will be displayed in the reservation calendar shortly. Please note that it may take up to 30 minutes for your reservation to appear in the calendar.",
      PastDateError: "Dates must be in the future.",
      CheckoutBeforeCheckinError:
        "Checkout date cannot be before checkin date.",
      ReservationDurationError: "Reservation cannot be longer than 14 days.",
      GuestFirstName: "Guest first name",
      GuestLastName: "Guest last name",
      CheckinDate: "Checkin date",
      CheckoutDate: "Checkout date",
    },
    Details: "Details",
    Yes: "Yes",
    SetStatus: "Set Status",
    Synchronize: "Sync",
    CustomerContracts: {
      NoContractsAssigned:
        "You don't have any contracts in your owner's portal yet. They should appear here soon. We ask for your patience. If you have questions or need help, please contact us. Thank you for your understanding!",
      Loading: "Loading contract data. Please wait.",
    },
    GuestLogin: {
      ReservationId: "Reservation ID",
      GuestLastName: "Guest Last Name",
      Login: "Login",
    },
    ErrorPage: {
      SomethingWentWrong: "Something went wrong on our side.",
      TryAgainLater:
        "Please try again later. If the problem persists, please contact our customer service.",
      ReturnToHomepage: "Return to home page",
    },
  },
};
