import { useApi } from "../shared/hooks/useApi";
import { useUpdateApi } from "../shared/hooks/useLazyApi";

export const usePricing = () =>
  useApi<
    {
      name: string;
      hostaway_id: number;
      min_price: number;
      base_price: number;
      property_id: number;
    }[],
    undefined
  >(`/v1/admin/pricing/property`);

export const usePropertyPricingDetails = (propertyId: number) =>
  useApi<
    Record<
      string,
      {
        note: string;
        date: string;
        minimumStay: number;
        price: number;
      }
    >,
    undefined
  >(`/v1/admin/pricing/property/${propertyId}`);

export const useUpdatePricing = () =>
  useUpdateApi<{
    min_price?: number;
    base_price?: number;
    property_id: number;
  }>(`/v1/admin/pricing/property`);
