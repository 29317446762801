import { SelectField } from "@aws-amplify/ui-react";
import moment from "moment";
import { useEffect, useMemo } from "react";
import { logger } from "../../../logger";

export default ({
  onChange,
  value,
  hideLabel,
  isDisabled,
}: {
  isDisabled?: boolean;
  hideLabel?: boolean;
  value: string | undefined | null;
  onChange: (value: string | null) => void;
}) => {
  useEffect(() => {
    logger.debug(value);
  }, [value]);
  const optionValues = useMemo(() => {
    return Array.from(Array(12).keys())
      .map((index) =>
        [0, 30].map((minutes) => {
          const optValue = moment(`${9 + index}:${minutes}`, "HH:mm").format(
            "HH:mm"
          );
          return optValue;
        })
      )
      .flat(1);
  }, []);

  return (
    <SelectField
      isDisabled={isDisabled}
      label={hideLabel ? undefined : "Time"}
      defaultValue={value || undefined}
      value={value || undefined}
      onChange={(event) => {
        logger.debug(event.target.value);
        onChange(event.target.value === "-" ? null : event.target.value);
      }}
    >
      <option key={"-"}>{"-"}</option>
      {optionValues.map((optValue, index) => {
        return <option key={index}>{optValue}</option>;
      })}
    </SelectField>
  );
};
