import { Button, Flex, TextField } from "@aws-amplify/ui-react";
import { useEffect } from "react";
import Loader from "./Loader";
import WorkRegionSelectField from "../../Users/WorkRegionSelectField";
import { useUserData } from "../hooks/useUserData";
import { CoreUserData } from "../types/core-user-data";
import useForm from "../hooks/useForm";

type UserDataUpdateFormValues = Pick<
  CoreUserData,
  "first_name" | "last_name" | "service_region" | "phone"
>;

export default ({
  onSaved,
  userId,
  handleUpdate,
}: {
  userId: number;
  onSaved: () => void;
  handleUpdate: (values: UserDataUpdateFormValues) => Promise<void>;
}) => {
  const { setDirty, valid, dirty, formValues, setFormValues, setFieldValue } =
    useForm<UserDataUpdateFormValues>(
      {
        phone: "",
        first_name: "",
        last_name: "",
        service_region: "",
      },
      {
        validate: (values) => {
          if (values.phone && values.first_name && values.last_name) {
            return true;
          }

          return false;
        },
      }
    );
  const { load, data, loading } = useUserData();

  useEffect(() => {
    load(userId);
  }, [userId, load]);

  useEffect(() => {
    if (data) {
      setFormValues(data);
    }
  }, [data, setFormValues]);

  return (
    <Flex direction="column">
      {loading && (
        <Flex direction={"column"} grow={1}>
          <Loader></Loader>
        </Flex>
      )}

      {data && !loading && (
        <Flex direction={"column"} gap={16}>
          <Flex direction={"column"} gap={0}>
            <TextField
              type="email"
              label={"Email"}
              value={data?.email}
              disabled={true}
              readOnly={true}
            ></TextField>
          </Flex>

          <Flex direction={"column"} gap={0}>
            <TextField
              type="text"
              label={"First name"}
              value={formValues.first_name}
              onChange={(event) => {
                setFieldValue("first_name", event.target.value);
              }}
            ></TextField>
          </Flex>

          <Flex direction={"column"} gap={0}>
            <TextField
              type="text"
              label={"Last name"}
              value={formValues.last_name}
              onChange={(event) => {
                setFieldValue("last_name", event.target.value);
              }}
            ></TextField>
          </Flex>

          <Flex direction={"column"} gap={0}>
            <TextField
              type="text"
              label={"Phone number"}
              value={formValues.phone}
              onChange={(event) => {
                setFieldValue("phone", event.target.value);
              }}
            ></TextField>
          </Flex>

          <Flex direction={"column"} gap={0}>
            <WorkRegionSelectField
              value={formValues.service_region}
              onChange={(newValue) => {
                setFieldValue("service_region", newValue);
              }}
            ></WorkRegionSelectField>
          </Flex>

          <Flex marginTop={32}>
            <Button
              onClick={async () => {
                if (valid) {
                  await handleUpdate(formValues);

                  setDirty(false);
                  load(userId);
                  onSaved();
                }
              }}
              isDisabled={!valid && !dirty}
              variation="primary"
              grow={1}
              disabled={!valid && !dirty}
            >
              Save
            </Button>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
