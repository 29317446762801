import { Flex, Image, ImageProps, Text } from "@aws-amplify/ui-react";
import Markdown from "react-markdown";
import styled from "styled-components";

export const StyledText = styled(Text)`
  text-wrap: pretty;
`;

const MarkdownContainer = ({ text }: { text: string }) => {
  return (
    <Markdown
      components={{
        img: (props) => {
          const { node, ...rest } = props;

          let imgProps = rest as ImageProps;

          return (
            <Flex
              justifyContent={"center"}
              padding={0}
              margin={8}
              boxShadow={"medium"}
            >
              <Image
                style={{
                  objectFit: "contain",
                  maxWidth: "80%",
                  maxHeight: 700,
                }}
                {...imgProps}
              />
            </Flex>
          ) as any;
        },
      }}
    >
      {text}
    </Markdown>
  );
};

export default MarkdownContainer;
