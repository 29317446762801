import { Flex } from "@aws-amplify/ui-react";
import dayGridPlugin from "@fullcalendar/daygrid";
import FullCalendar from "@fullcalendar/react";
import moment from "moment";
import { useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import i18n from "../../i18n/i18n";
import { theme } from "../../theme";
import LoaderBox from "../shared/components/LoaderBox";
import { dateToYYMMDD } from "../shared/utils/dates.util";
import FullCalendarWrapper from "./FullCalendarWrapper";
import { usePropertyPricingDetails } from "./usePricing";

const mapToCalendarEvents = (
  calendarItems: Record<
    string,
    {
      date: string;
      minimumStay: number;
      note: string;
    }
  >
) => {
  return Object.keys(calendarItems).map((itemKey) => {
    const color = theme.primaryColor;

    return {
      title: `Min. ${calendarItems[itemKey].minimumStay} Nights`,
      start: calendarItems[itemKey].date,
      end: dateToYYMMDD(moment(calendarItems[itemKey].date).add(1, "day")),
      color,
      borderColor: color,
    };
  });
};

export default () => {
  const params = useParams<{ propertyId: string }>();
  const { data, loading } = usePropertyPricingDetails(
    Number(params.propertyId)
  );
  const calendarRef = useRef(null);

  const events = useMemo(() => {
    return data ? mapToCalendarEvents(data) : null;
  }, [data]);

  return (
    <Flex direction="column" grow={1}>
      {loading && <LoaderBox />}

      {!loading && events && (
        <FullCalendarWrapper>
          <FullCalendar
            ref={calendarRef}
            datesSet={(_arg) => {
              /**const midDate = new Date(
                (arg.start.getTime() + arg.end.getTime()) / 2
              );*/
              /**
          onMonthChange(
            moment(midDate).format("YYYY"),
            moment(midDate).format("MM")
          );
           */
            }}
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            locale={i18n.language}
            firstDay={1}
            events={events}
            height={"80vh"}
            eventColor={theme.primaryColor}
            buttonText={{
              today: "Today",
            }}
          />
        </FullCalendarWrapper>
      )}
    </Flex>
  );
};
