import {
  Alert,
  CheckboxField,
  Divider,
  Flex,
  Heading,
} from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import TextMuted from "../shared/components/TextMuted";
import {
  TaskTemplateRequirements,
  useTaskRequirementValueUpdate,
  TaskRequirementsValues,
} from "../shared/hooks/useTasksApi";
import { keyBy, mapValues } from "lodash";
import AlertModal from "../shared/AlertModal";
import { useTranslation } from "react-i18next";

export default function TaskRequirements({
  templateData,
  data,
  taskId,
  onChange,
  readonly,
}: {
  readonly: boolean;
  data?: TaskRequirementsValues;
  onChange: () => void;
  templateData: TaskTemplateRequirements;
  taskId: string;
}) {
  const { t } = useTranslation();
  const [values, setValues] = useState<Record<number, boolean>>({});
  const { update } = useTaskRequirementValueUpdate(taskId);
  const [error, setError] = useState<
    { title: string; describtion: string } | undefined
  >();

  useEffect(() => {
    if (data) {
      setValues(
        mapValues(keyBy(data?.requirements || [], "requirement_id"), "value")
      );
    }
  }, [data, setValues]);

  return (
    <>
      {!!error && (
        <AlertModal
          onClose={() => {
            setError(undefined);
          }}
          title={error?.title}
        >
          {error?.describtion}
        </AlertModal>
      )}
      <Flex direction={"column"}>
        <Flex direction={"column"}>{templateData?.name}</Flex>
        <Flex direction={"column"}>{templateData?.description}</Flex>

        {data && data.fullfilled < data.requirementsCount && (
          <Alert>
            {t("Service.RequirementsInfo")}
            {`${data.fullfilled} / ${data.requirementsCount}`}
          </Alert>
        )}

        {data && data.fullfilled >= data.requirementsCount && (
          <Alert variation="success">All requirements fullfilled.</Alert>
        )}

        {templateData.sections.map((section) => (
          <Flex direction={"column"}>
            <Flex direction={"column"}>
              <Heading level={5}>{section?.name}</Heading>

              <Divider></Divider>
            </Flex>

            {section.requirements.map((requirement) => (
              <Flex direction={"row"} gap={36} alignItems={"center"}>
                <Flex direction={"column"}>
                  <TextMuted>{requirement.requirement_type}</TextMuted>
                </Flex>

                <Flex direction={"column"} alignItems={"center"}>
                  <CheckboxField
                    size="large"
                    label={""}
                    name={`${requirement.id}`}
                    checked={!!values[requirement.id]}
                    value={String(!!values[requirement.id])}
                    onClick={async () => {
                      if (readonly) {
                        return setError({
                          title: "Warning",
                          describtion: `You are not assigned to this task. Cannot set requirements.`,
                        });
                      }

                      const previousValue = values[requirement.id];
                      setValues({
                        ...values,
                        [requirement.id]: !values[requirement.id],
                      });

                      try {
                        await update({
                          requirement_id: requirement.id,
                          value: !values[requirement.id],
                        });

                        onChange();
                      } catch (err) {
                        setValues({
                          ...values,
                          [requirement.id]: previousValue,
                        });

                        setError({
                          title: "Error",
                          describtion: `Not able to update requirements. Contact support.`,
                        });
                      }
                    }}
                  ></CheckboxField>
                </Flex>

                <Flex direction={"column"}>
                  {requirement.actions.map((action) => (
                    <Flex direction={"row"}>
                      <Flex direction={"row"} alignItems={"center"}>
                        {action.name}
                      </Flex>
                    </Flex>
                  ))}
                </Flex>
              </Flex>
            ))}
          </Flex>
        ))}
      </Flex>
    </>
  );
}
