import { Flex, Link } from "@aws-amplify/ui-react";
import { useEffect } from "react";
import GenericTable from "../shared/GenericTable";
import GenericTablePage from "../shared/GenericTablePage";
import { useLazyProperties } from "../shared/hooks/useProperties";
import SearchFieldCard from "../shared/components/SearchFieldCard";
import { CorePropertyData } from "../shared/hooks/core-property";
import { useTranslation } from "react-i18next";

export default () => {
  const { t } = useTranslation();
  const { data, load, loading } = useLazyProperties();

  useEffect(() => {
    load();
  }, [load]);

  return (
    <GenericTablePage title={t("Service.Properties")}>
      <Flex direction={"column"} overflow={"hidden"} grow={1}>
        <Flex grow={1} padding={1}>
          <Flex>
            <Flex direction={"column"} gap={0}>
              <SearchFieldCard
                placeholder={"Search property name or ID"}
                onSearch={(query) => {
                  load({ query });
                }}
              ></SearchFieldCard>
            </Flex>
          </Flex>
        </Flex>

        <GenericTable
          columnComponents={{
            name: (item: CorePropertyData) => (
              <Link href={`/property/details?id=${item.internal_id}`}>
                {item.name}
              </Link>
            ),
          }}
          loading={loading}
          data={data}
        ></GenericTable>
      </Flex>
    </GenericTablePage>
  );
};
