import { useCallback, useEffect, useState } from "react";
import { ApiHook, ReportTable } from "./common";
import { API } from "../utils/API";

export interface CorePropertyAccess {
  internal_id: number;
  hostaway_id: number;
  name: string;
  checkin_instructions: string;
  checkin_instructions_pl: string;
  past_checkin_instructions: string;
  past_checkin_instructions_pl: string;
  parking_info: string;
  parking_info_pl: string;
  door_code: string;
  floor: string;
}

const getPropertiesInstructions = async (): Promise<
  ReportTable<CorePropertyAccess, undefined>
> => await API.get(`/v1/admin/contracts/properties-instructions`);

export const usePropertyInstructions: ApiHook<
  ReportTable<CorePropertyAccess, undefined>,
  undefined
> = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] =
    useState<ReportTable<CorePropertyAccess, undefined>>();
  const [error, setError] = useState<unknown>();
  const refetch = useCallback(() => {
    setLoading(true);

    (async () => {
      try {
        setData(await getPropertiesInstructions());
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [setLoading, setData, setError]);

  useEffect(() => {
    if (!data) {
      refetch();
    }
  }, [refetch, data]);

  return { data, loading, error, refetch };
};
